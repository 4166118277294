.wrapper {
  width: 100%;

  .subWrapper {
    display: flex;
    flex-flow: column;
    width: 100%;
    max-width: 940px;
    padding: 0 60px;
    margin: 3.12vw auto 0;

    .date {
      font-size: 24px;
      color: var(--theme-color12);
      font-weight: 500;
      margin-top: 20px;
      padding-bottom: 15px;
      border-bottom: 12px solid var(--theme-color-main);
      text-align: right;
      margin-bottom: 20px;
      transition: 0.3s all;
    }

    .title {
      font-size: 20px;
      color: var(--theme-color12);
      margin-top: 25px;
      transition: 0.3s all;
    }

    .text {
      font-size: 20px;
      color: var(--theme-color12);
      margin: 24px 0;
      transition: 0.3s all;
    }

    .place {
      font-size: 16px;
      color: var(--theme-color5);
      padding-bottom: 45px;
      border-bottom: 3px dotted var(--theme-color5);
      margin-bottom: 35px;
      transition: 0.3s all;
    }

    .mobileData {
      display: none;
      flex-flow: column;

      .dateMobile {
        font-size: 16px;
        color: var(--theme-color5);
        margin-top: 15px;
        text-align: center;
        transition: 0.3s all;
      }

      .titleMobile{
        font-size: 17px;
        color: var(--theme-color12);
        margin-top: 20px;
        transition: 0.3s all;
        text-align: center;
      }

      .textMobile {
        font-size: 17px;
        color: var(--theme-color12);
        margin: 20px 0;
        text-align: center;
        transition: 0.3s all;
      }

      .placeMobile {
        font-size: 16px;
        color: var(--theme-color5);
        padding-bottom: 20px;
        margin-bottom: 23px;
        transition: 0.3s all;
        border-bottom: 5px solid var(--theme-color-main);
        text-align: center;
      }
    }

    .mobileSeparator {
      display: none;
      border-bottom: 5px solid var(--theme-color3);
      margin: 23px 0;
    }
  }
}

@media screen and (max-width: 1400px){
  .wrapper {

    .subWrapper {
      padding: 0 40px;
    }
  }
}

@media screen and (max-width: 800px){
  .wrapper {

    .subWrapper{

      .date {
        font-size: 22px;
        margin-top: 15px;
        padding-bottom: 10px;
        margin-bottom: 15px;
      }

      .title {
        font-size: 18px;
        margin-top: 20px;
      }

      .text {
        font-size: 18px;
        margin: 19px 0;
      }

      .place {
        font-size: 14px;
        padding-bottom: 30px;
        margin-bottom: 28px;
      }
    }
  }
}

@media screen and (max-width: 520px){
  .wrapper {

    .subWrapper {
      padding: 0 30px;
    }
  }
}

@media screen and (max-width: 450px){
  .wrapper {

    .subWrapper {

      .date {
        display: none;
      }

      .title {
        display: none;
      }

      .text {
        display: none;
      }

      .place {
        display: none;
      }

      .mobileData {
        display: flex;
      }

      .mobileSeparator {
        display: flex;
      }
    }
  }
}

@media screen and (max-width: 400px){
  .wrapper {

    .subWrapper {
      padding: 0 20px;
    }
  }
}