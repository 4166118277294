.modalBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  transition: opacity 0.2s linear, visibility 0.3s linear 0.3ms;
  opacity: 0.5;
  z-index: 10000;
  visibility: visible;
}

.modalBackgroundHidden {
  opacity: 0;
  visibility: hidden;
}

.modalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10010;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  transition: opacity 0.2s linear, visibility 0.3s linear 0.3ms;
  visibility: visible;
  opacity: 1;
  display: flex;

  .modalContainer {
    display: flex;
    max-width: 800px;
    transition: transform 0.3s ease-out;
    width: 100%;
    padding: 40px;
    margin: auto;
    transform: translate(0, 0);

    .modal {
      width: 100%;
      height: 100%;
      background-color: #ffffff;
      padding: 55px;
      position: relative;
      border-radius: 5px;

      .close {
        background-image: url("../../assets/images/other/closeBlack.png");
        width: 40px;
        height: 40px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;
        transition: 0.3s all;

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
}

.modalWrapperHidden {
  opacity: 0;
  visibility: hidden;

  .modalContainer {
    transform: translate(0, -20%);
  }
}

@media screen and (max-width: 1000px) {
  .modalWrapper {

    .modalContainer {
      padding: 30px;

      .modal {
        padding: 50px;

        .close {
          width: 36px;
          height: 36px;
          right: 15px;
          top: 15px;
        }
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .modalWrapper {

    .modalContainer {
      padding: 15px;

      .modal {
        padding: 30px;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .modalWrapper {

    .modalContainer {

      .modal {
        padding: 20px;
      }
    }
  }
}