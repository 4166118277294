.wrapper {
  display: flex;
  flex-flow: column;
  width: 100%;

  .top {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-top: 12px solid var(--theme-color-main);
    padding: 25px 0 15px;
    transition: 0.3s border;

    .chooseWrapper {
      font-size: 18px;
      font-weight: 500;
      width: 176px;
      display: flex;
      flex-shrink: 0;
      flex-wrap: wrap;
      transition: 0.3s all;

      span {
        margin-right: 3px;
      }
    }

    .versionWrapper {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between;
      max-width: 500px;

      .version {
        /*width: calc((100% - 10px) / 2);*/
        flex-basis: calc((100% - 10px) / 2);
        display: flex;
        justify-content: center;
        border: 2px solid var(--theme-color3);
        transition: 0.3s border, 0.3s background, 0.3s color;
        margin-bottom: 10px;
        padding: 10px 5px;
        cursor: pointer;
        white-space: nowrap;
        font-size: 16px;
        user-select: none;
        align-items: center;
      }

      .versionActive {
        border: 2px solid var(--theme-color-main);
        background-color: var(--theme-color2);
        color: var(--theme-color1);
      }

      .versionMargin{
        margin-bottom: 0;
      }
    }
  }

  .bottom {
    display: flex;
    width: 100%;
    border-top: 4px dotted var(--theme-color3);
    transition: 0.3s border;
    padding-top: 15px;
    justify-content: right;

    .clear {
      padding: 13px 10px;
      color: var(--theme-color1);
      background-color: var(--theme-color15);
      width: 100%;
      max-width: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: 0.3s color, 0.3s background-color, 0.3s transform;
      user-select: none;
    }

    .clear:hover {
      transform: scale(1.05);
    }

    .show {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      max-width: 200px;
      font-size: 16px;
      padding: 13px 10px;
      color: var(--theme-color10);
      background-color: var(--theme-color-main);
      cursor: pointer;
      margin-left: 15px;
      transition: 0.3s color, 0.3s background-color, 0.3s transform;
      user-select: none;
    }

    .show:hover {
      transform: scale(1.05);
    }
  }
}

.presWrapper {
  width: 100%;
  max-width: 1480px;
  padding: 0 60px;
  margin: 0 auto;
  flex-flow: row;

  .top {
    border-top: unset;
    justify-content: center;
    padding: unset;
    flex-wrap: wrap;

    .chooseWrapper {
      color: var(--theme-color-main);
      width: unset;
      flex: 1 1 0;
      margin-top: 16px;
      margin-right: 15px;

      span {
        white-space: nowrap;
      }
    }

    .versionWrapper {
      flex-flow: row;
      max-width: unset;
      flex-grow: 1;
      flex-basis: 75%;
      margin-top: 16px;
      align-items: center;

      .version {
        flex-basis: unset;
        width: 100%;
        margin-left: 10px;
      }

      .version:nth-child(1) {
        margin-bottom: unset;
        margin-left: unset;
      }

      .version:nth-child(2) {
        margin-bottom: unset;
      }
    }
  }

  .bottom {
    width: unset;
    border-top: unset;
    border-left: 3px dotted var(--theme-color3);
    padding-top: unset;
    padding-left: 15px;
    margin-left: 15px;
    justify-content: unset;
    align-items: center;
    margin-top: 16px;

    .clear {
      width: 142px;
      padding: 12px 10px;
    }

    .show {
      width: 142px;
      padding: 12px 10px;
    }
  }
}

@media screen and (max-width: 1400px){
  .presWrapper {
    padding: 0 40px;
  }
}

@media screen and (max-width: 1350px){
  .presWrapper {

    .top {
      flex-flow: column;

      .chooseWrapper {
        margin-right: unset;
        flex: unset;
        align-items: center;
        margin-top: auto;
      }

      .versionWrapper {
        margin-top: auto;
        flex-basis: unset;
        flex-grow: unset;
      }
    }

    .bottom {
      flex-flow: column;

      .clear {
      }

      .show {
        margin-left: unset;
        margin-top: 15px;
      }
    }
  }
}

@media screen and (max-width: 1150px){
  .wrapper {

    .top {
      flex-flow: column;

      .chooseWrapper {
        width: unset;
        margin-bottom: 20px;
      }
    }

    .bottom {
      justify-content: center;
    }
  }

  .presWrapper {

    .top {
      flex-flow: wrap;

      .chooseWrapper {
        margin-bottom: unset;
      }
    }
  }
}

@media screen and (max-width: 1000px){
  .presWrapper {

    .top {

      .chooseWrapper {
        margin-top: 16px;
      }

      .versionWrapper {
        flex-wrap: wrap;

        .version {
          width: calc(50% - 5px);
          margin-left: unset;
          margin-top: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 850px){
  .wrapper {

    .top {

      .versionWrapper {
        flex-flow: column;

        .version:nth-child(3) {
          margin-bottom: 10px;
        }
      }
    }
  }

  .presWrapper {

    .top {

      .versionWrapper {
        flex-flow: wrap;

        .version:nth-child(3) {
          margin-bottom: unset;
        }
      }
    }
  }
}

@media screen and (max-width: 800px){
  .wrapper {

    .top {
      border-top: 9px solid var(--theme-color-main);

      .chooseWrapper {
        font-size: 16px;
      }

      .versionWrapper {

        .version {
          font-size: 15px;
        }
      }
    }

    .bottom {

      .clear {
        font-size: 15px;
      }

      .show {

        a {
          font-size: 15px;
        }
      }
    }
  }

  .presWrapper {

    .top {
      border: unset;

      .chooseWrapper {
        font-size: 18px;
      }
    }
  }
}

@media screen and (max-width: 750px){
  .presWrapper {
    flex-flow: column;

    .top {
      border-top: 6px solid var(--theme-color-main);
      margin-top: 20px;
    }


    .bottom {
      border-top: 4px dotted var(--theme-color3);
      border-left: unset;
      margin-left: unset;
      padding-left: unset;

      .clear {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 620px){
  .wrapper {

    .top {
      border: unset;
      padding-top: 30px;

      .chooseWrapper {
        flex-flow: column;
        align-items: center;
      }

      .versionWrapper {
        flex-flow: row;
        flex-wrap: wrap;

        .version:nth-child(3) {
          margin-bottom: unset;
        }
      }
    }

    .bottom {
      .clear {
        display: none;
      }
    }
  }

  .presWrapper {
    flex-flow: column;

    .top {
      margin-top: 20px;
      padding-top: unset;
      border-top: 6px solid var(--theme-color-main);
    }
  }
}

@media screen and (max-width: 620px) {
  .presWrapper {
    padding: 0 30px;
  }
}

@media screen and (max-width: 450px){
  .wrapper {

    .top {

      .versionWrapper {
        flex-flow: column;

        .version:nth-child(3) {
          margin-bottom: 10px;
        }
      }
    }
  }

  .presWrapper {

    .top {

      .versionWrapper {

        .version {
          margin-top: 10px;
          margin-bottom: unset;
          width: 100%;
        }

        .version:nth-child(3) {
          margin-bottom: unset;
        }
      }
    }

    .bottom {
      margin-bottom: 30px;
    }
  }
}

@media screen and (max-width: 400px) {
  .presWrapper {
    padding: 0 20px;

    .bottom {
      margin-bottom: 20px;
    }
  }
}