.wrapper {
  width: 100%;

  .subSubWrapper {
    display: flex;
    width: 100%;
    max-width: 1480px;
    padding: 0 60px;
    margin: 3.68vw auto 0;

    .imageWrapper {
      position: relative;
      width: 100%;
      max-width: 530px;
      margin-right: 70px;
      flex-shrink: 0;

      .image {
        width: 100%;
        background-position: top;
        background-repeat: no-repeat;
        background-size: 100% auto;
        display: flex;
        position: sticky;
        top: calc((100vh - 750px) / 2);
      }
    }

    .right {
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      width: 100%;

      .name {
        font-size: 24px;
        color: var(--theme-color-main);
        font-weight: 500;
      }

      .uni{
        font-size: 16px;
        color: var(--theme-color5);
        margin-top: 20px;
      }

      .mail {
        font-size: 16px;
        color: var(--theme-color5);
        margin-top: 5px;
      }

      .translate {
        font-size: 19px;
        color: var(--theme-color12);
        font-weight: 500;
        margin-top: 30px;
        border-top: 12px solid var(--theme-color-main);
        padding-top: 30px;
        width: 100%;
      }

      .tractateWrapper {
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        margin-bottom: auto;
      }

      .tractate {
        font-size: 18px;
        color: var(--theme-color12);
        margin-top: 10px;
      }

      a.tractate {

        span {
          background: linear-gradient(to right, rgb(255, 255, 255), rgb(255, 255, 255)), linear-gradient(to right, var(--theme-color12), var(--theme-color12));
          background-size: 100% 2px, 0 2px;
          background-position: 100% 100%, 0 100%;
          background-repeat: no-repeat;
          transition: background-size 400ms;
          padding-bottom: 3px;
          line-height: 1.3;
        }

        &:hover {

          span {
            background-size: 0 2px, 100% 2px;
          }
        }
      }

      .text {
        font-size: 18px;
        color: var(--theme-color12);
        border-top: 12px solid var(--theme-color3);
        width: 100%;
        margin-top: 35px;
        padding-top: 25px;
        margin-bottom: 20px;

        p {
          font-size: 18px;
          color: var(--theme-color12);
        }

        a {
          color: var(--theme-color12);
        }
      }

      .backWrapper {
        padding-top: 10px;
        width: 100%;
        display: flex;

        .back {
          font-size: 20px;
          color: var(--theme-color-main);
          display: flex;
          flex-shrink: 0;
          align-items: center;
          position: relative;

          .arrow {
            background-color: var(--theme-color-main);
            clip-path: polygon(0 50%, 100% 100%, 100% 0);
            width: 14px;
            height: 12px;
            margin-right: 10px;
          }

          &::after {
            content: '';
            position: absolute;
            bottom: -3px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: var(--theme-color-main);
            opacity: 0;
            transition: opacity 300ms, transform 300ms;
          }

          &:hover::after{
            opacity: 1;
            transform: translate3d(0, 0.2em, 0);
          }

          &:after {
            opacity: 1;
            transform: scale(0);
            transform-origin: center;
          }

          &:hover::after {
            transform: scale(1);
          }
        }
      }
    }
  }

  .subWrapper {
    display: flex;
    flex-flow: column;
  }
}

@media screen and (max-width: 1400px) {
  .wrapper {

    .subSubWrapper {
      padding: 0 40px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .wrapper {

    .subSubWrapper {

      .imageWrapper {
        margin-right: 40px;
        width: 100%;
        max-width: 44.16vw;

        .image {
          width: 100%;
          max-width: 44.16vw;
          top: calc((100vh - 62.5vw) / 2);
        }
      }

    }
  }
}

@media screen and (max-width: 1000px) {
  .wrapper {

    .subSubWrapper {

      .right {

        .name {
          font-size: 22px;
        }

        .uni{
          font-size: 16px;
        }

        .mail {
          font-size: 16px;
        }

        .translate {
          font-size: 17px;
          margin-top: 30px;
          padding-top: 25px;
        }

        .tractate {
          font-size: 16px;
          margin-top: 10px;
        }

        .text {
          font-size: 16px;
          margin-top: 30px;
          padding-top: 20px;

          p {
            font-size: 16px;
          }
        }

        .backWrapper {
          padding-top: 8px;

          .back {
            font-size: 18px;

            .arrow {
              width: 12px;
              height: 10px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .wrapper {

    .subSubWrapper {

      .right {

        .text {
          border-top: 9px solid var(--theme-color3);
        }

        .translate {
          border-top: 9px solid var(--theme-color-main);
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .wrapper {

    .subSubWrapper {
      flex-flow: column;

      .imageWrapper {
        width: 100%;
        max-width: unset;
        margin-right: unset;
        height: unset;

        .image {
          width: 100%;
          max-width: unset;
        }
      }

      .right{
       align-items: center;

        .name {
          font-size: 24px;
          font-weight: 500;
          margin-top: 25px;
          text-align: center;
        }

        .uni{
          font-size: 16px;
          margin-top: 20px;
          text-align: center;
        }

        .mail {
          font-size: 16px;
          margin-top: 5px;
          text-align: center;
        }

        .translate {
          font-size: 21px;
          margin-top: 20px;
          padding-top: 25px;
          text-align: center;
        }

        .tractate {
          font-size: 19px;
          text-align: center;
        }

        .text {
          font-size: 18px;
          margin-top: 30px;
          padding-top: 25px;

          p {
            font-size: 18px;
          }
        }

        .backWrapper {
          padding-top: 10px;
          justify-content: center;

          .back {
            font-size: 20px;

            .arrow {
              width: 14px;
              height: 12px;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 620px) {
  .wrapper {

    .subSubWrapper {

      .right {

        .text {
          border-top: 6px solid var(--theme-color3);
        }

        .translate {
          border-top: 6px solid var(--theme-color-main);
        }
      }
    }
  }
}

@media screen and (max-width: 520px) {
  .wrapper {

    .subSubWrapper {
      padding: 0 30px;

      .right{

        .name {
          font-size: 22px;
          margin-top: 20px;
        }

        .uni{
          font-size: 15px;
          margin-top: 15px;
        }

        .mail {
          font-size: 15px;
          margin-top: 5px;
        }

        .translate {
          font-size: 18px;
          margin-top: 20px;
          padding-top: 20px;
        }

        .tractate {
          font-size: 17px;
        }

        .text {
          font-size: 16px;
          margin-top: 20px;
          padding-top: 20px;

          p {
            font-size: 16px;
          }
        }

        .backWrapper {
          padding-top: 8px;
          justify-content: center;

          .back {
            font-size: 18px;

            .arrow {
              width: 12px;
              height: 10px;
              margin-right: 8px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .wrapper {

    .subSubWrapper {
      padding: 0 20px;
    }
  }
}