.wrapper {
  display: flex;
  flex-flow: row;
  width: 100vw;
  max-width: 1480px;
  padding: 16px 60px;
  margin: 0 auto;
  overflow-x: auto;
  transition: 0.3s all;
  flex-shrink: 1;

  .backWrapper {
    margin-right: 10px;
    transition: 0.3s all;
    position: relative;
    font-size: 18px;
    cursor: pointer;
    user-select: none;
  }

  .backWrapper::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--theme-color12);
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
  }

  .backWrapper:hover::after,
  .backWrapper:focus::after {
    opacity: 1;
    transform: translate3d(0, 0.2em, 0);
  }

  .backWrapper:after {
    opacity: 1;
    transform: scale(0);
    transform-origin: center;
  }

  .backWrapper:hover::after,
  .backWrapper:focus::after{
    transform: scale(1);
  }

  a {
    color: var(--theme-color5);
    transition: 0.3s all;
    position: relative;
    font-size: 18px;
  }

  a:hover {
    color: var(--theme-color12);
  }

  a::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--theme-color12);
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
  }

  a:hover::after,
  a:focus::after {
    opacity: 1;
    transform: translate3d(0, 0.2em, 0);
  }

  a:after {
    opacity: 1;
    transform: scale(0);
    transform-origin: center;
  }

  a:hover::after,
  a:focus::after{
    transform: scale(1);
  }

  .separator {
    color: var(--theme-color5);
    transition: 0.3s all;
    padding: 0 5px;
    cursor: default;
    font-size: 18px;
  }

  .lastItem {
    color: var(--theme-color12);
    cursor: default;
    font-size: 18px;
    white-space: nowrap;
    user-select: none;
  }

  .smaller {
    max-width: 250px;
    width: 100%;
    display: flex;

    span {
      max-width: 250px;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
      white-space: nowrap;
    }
  }

  .back {
    display: flex;
    justify-content: center;
    align-items: center;

    .text {
      color: var(--theme-color12);
      padding-left: 10px;
    }
  }

  .breadcrumb {
    display: flex;
    flex-shrink: 0;
    white-space: nowrap;
    user-select: none;
    transition: 0.3s all;
  }

  .arrow {
    background-color: var(--theme-color12);
    clip-path: polygon(0 50%, 100% 100%, 100% 0);
    width: 14px;
    height: 12px;
  }
}

@media screen and (max-width: 1400px) {
  .wrapper {
    padding: 16px 40px;
  }
}

@media screen and (max-width: 800px) {
  .wrapper {

    .backWrapper {
      font-size: 15px;
    }

    a {
      font-size: 15px;
    }

    .separator {
      font-size: 15px;
    }

    .lastItem {
      font-size: 15px;
    }

    .smaller {
      max-width: 200px;

      span {
        max-width: 200px;
      }
    }

    .arrow {
      width: 11px;
      height: 9px;
    }
  }
}

@media screen and (max-width: 520px) {
  .wrapper {
    padding: 16px 30px;
  }
}

@media screen and (max-width: 400px) {
  .wrapper {
    padding: 16px 20px;
  }
}