.wrapper {
  width: 100%;

  .subWrapper {
    display: flex;
    width: 100%;
    max-width: 1480px;
    padding: 0 60px;
    margin: 2.60vw auto 0;
    position: relative;
    flex-flow: row;

    .left {

      .link {
        display: none;
      }

      .title {
        display: none;
      }

      .image {
        width: 533px;
        height: auto;
        position: sticky;
        top: 30px;
      }
    }

    .right {
      display: flex;
      flex-flow: column;
      margin-left: 75px;

      .link {
        display: flex;

        a {
          color: var(--theme-color-main);
          font-size: 28px;
          text-decoration: underline;
          transition: 0.3s color;
        }
      }

      .title {
        color: var(--theme-color12);
        font-size: 28px;
        font-weight: 500;
        margin:10px 0 20px;
      }

      .firstEditionWrapper {
        border-top: 12px solid var(--theme-color3);
        padding-top: 30px;
        margin-top: 30px;
        display: flex;
        transition: 0.3s border;

        .firstEditionTitle {
          font-weight: 500;
          font-size: 18px;
          margin-right: 4px;
        }

        .firstEdition {
          font-size: 18px;
        }
      }

      .translationWrapper {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        margin-top: 20px;

        .translationTitle {
          font-size: 18px;
          color: var(--theme-color12);
          font-weight: 500;
          flex-shrink: 0;
          margin-right: 4px;
        }

        .translations {
          display: flex;
          flex-wrap: wrap;

          .name {
            margin-left: 4px;

            &:nth-child(1) {
              margin-left: 0;
            }
          }

          .translation {
            font-size: 18px;
            color: var(--theme-color12);
            display: flex;
            margin-right: 4px;

            a {
              color: var(--theme-color12);
              font-size: 18px;
              text-decoration: underline;
              margin-left: 4px;
              flex-shrink: 0;
            }
          }
        }
      }

      .entryTitle {
        border-top: 12px solid var(--theme-color3);
        padding-top: 30px;
        margin-top: 30px;
        margin-bottom: 15px;
        font-size: 18px;
        font-weight: 500;
        transition: 0.3s border;
      }

      .entry {
        font-size: 18px;
        margin-bottom: 20px;

        p {
          display: none;
        }

        blockquote {
          display: none;
        }

        p:first-child {
          display: block;
          overflow: hidden;
        }

        sup {
          display: none;
        }
      }

      .entryReadAll {
        margin-top: auto;

        a {
          font-size: 18px;
          color: var(--theme-color-main);
          transition: 0.3s color;
          font-weight: 500;
          position: relative;
          align-self: flex-start;

          &::after {
            content: '';
            position: absolute;
            bottom: -3px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: var(--theme-color-main);
            opacity: 0;
            transition: opacity 300ms, transform 300ms;
          }

          &:hover::after{
            opacity: 1;
            transform: translate3d(0, 0.2em, 0);
          }

          &:after {
            opacity: 1;
            transform: scale(0);
            transform-origin: center;
          }

          &:hover::after {
            transform: scale(1);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1480px) {
  .wrapper {

    .subWrapper {

      .left {

        .image {
          width: 36.01vw;
        }
      }

      .right {
        margin-left: 3.90vw;
      }
    }
  }
}



@media screen and (max-width: 1400px) {
  .wrapper {

    .subWrapper {
      padding: 0 40px;
    }
  }
}

@media screen and (max-width: 800px) {
  .wrapper {

    .subWrapper {

      .right {

        .link {

          a {
            font-size: 24px;
          }
        }

        .title {
          font-size: 24px;
          margin-top: 5px;
          margin-bottom: 15px;
        }

        .firstEditionWrapper {
          border-top: 9px solid var(--theme-color3);
          margin-top: 20px;
          padding-top: 20px;

          .firstEditionTitle {
            font-size: 16px;
          }

          .firstEdition {
            font-size: 16px;
          }
        }

        .translationWrapper {

          .translationTitle {
            font-size: 16px;
          }

          .translations {

            .translation {
              font-size: 16px;

              a {
                font-size: 16px;
              }
            }
          }
        }

        .entryTitle {
          border-top: 9px solid var(--theme-color3);
          margin-top: 20px;
          padding-top: 20px;
          font-size: 16px;
        }

        .entry {
          font-size: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 620px) {
  .wrapper {

    .subWrapper {
      flex-flow: column;

      .left {
        display: flex;
        flex-flow: column;
        align-items: center;

        .link {
          display: block;

          a {
            color: var(--theme-color-main);
            font-size: 24px;
            text-decoration: underline;
            transition: 0.3s color;
          }
        }

        .title {
          display: block;
          color: var(--theme-color12);
          font-size: 24px;
          font-weight: 500;
          margin:10px 0 30px;
          width: 100%;
          text-align: center;
          padding-bottom: 20px;
          border-bottom: 6px solid var(--theme-color-main);
          transition: 0.3s border;
        }

        .image {
          width: 80%;
          margin: 0 auto;
          max-width: 350px;
        }
      }

      .right {
        margin-left: unset;

        .link {
          display: none;
        }

        .title {
          display: none;
        }

        .firstEditionWrapper {
          align-items: center;
          justify-content: center;
          border-top: 6px solid var(--theme-color3);
        }

        .translationWrapper {
          flex-flow: column;
          align-items: center;
          margin-top: 10px;

          .translations {
            align-items: center;
            justify-content: center;

            .translation {
              margin-top: 3px;
            }
          }
        }

        .entryTitle {
          border-top: 6px solid var(--theme-color3);
        }

        .entryReadAll {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
@media screen and (max-width: 520px) {
  .wrapper {

    .subWrapper {
      padding: 0 30px;

      .left {

        .link {

          a {
            font-size: 20px;
          }
        }

        .title {
          font-size: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .wrapper {

    .subWrapper {
      padding: 0 20px;
    }
  }
}