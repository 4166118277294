.headerWrapper {
  background-color: var(--theme-color-main);
  width: 100%;
  transition: 0.3s all;

  .subWrapper {
    display: flex;
    width: 100%;
    padding: 0 60px;
    transition: 0.3s all;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    .title {
      font-size: 50px;
      color: var(--theme-color1);
      font-family: "Bodoni Moda", sans-serif;
      text-align: center;
      transition: 0.3s color;
      font-style: italic;
    }

    .subtitle {
      font-size: 50px;
      letter-spacing: 1px;
      color: var(--theme-color6);
      font-family: "Bodoni Moda", sans-serif;
      text-align: center;
      margin-bottom: 40px;
      transition: 0.3s color;
      font-style: italic;
    }
  }
}

.headerSubWrapper {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1560px) {
  .headerWrapper {

    .subWrapper {

      .title {
        max-width: 1100px;
      }
    }
  }
}


@media screen and (max-width: 1400px) {
  .headerWrapper {

    .subWrapper {
      padding: 0 40px;
    }
  }
}

@media screen and (max-width: 850px) {
  .headerWrapper {

    .subWrapper {

      .title {
        font-size: 5.7vw;
      }

      .subtitle {
        font-size: 5.7vw;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .headerWrapper {

    .subWrapper {

      .subtitle {
        line-height: 1;
        margin-bottom: 30px;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .headerWrapper {

    .subWrapper {

      .title {
        font-size: 33px;
      }

      .subtitle {
        font-size: 33px;
      }
    }
  }
}

@media screen and (max-width: 520px) {
  .headerWrapper {

    .subWrapper {
      padding: 0 30px;
    }
  }
}

@media screen and (max-width: 400px) {
  .headerWrapper {

    .subWrapper {
      padding: 0 20px;
    }
  }
}

@media screen and (max-width: 350px) {
  .headerWrapper {

    .subWrapper {

      .title {
        font-size: 30px;
      }

      .subtitle {
        font-size: 30px;
      }
    }
  }
}

