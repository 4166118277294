.wrapper {
  width: 100%;

  .subWrapper {
    display: flex;
    flex-flow: column;
    width: 100%;
    max-width: 940px;
    padding: 0 60px;
    margin: 0 auto;
    position: relative;

    .title {
      font-size: 24px;
      color: var(--theme-color12);
      width: 100%;
      border-bottom: 12px solid var(--theme-color-main);
      padding-bottom: 25px;
      transition: 0.3s all;
      display: flex;
      margin-top: 30px;
    }

    .container {
      display: flex;
      flex-flow: column;
      padding: 40px 0;
      border-bottom: 3px dotted var(--theme-color5);
      align-items: flex-start;

      .author {
        font-size: 20px;
        color: var(--theme-color-main);
        font-weight: 500;
        margin-bottom: 25px;
      }

      .tractateTitle {
        font-size: 24px;
        color: var(--theme-color12);
        font-weight: 500;
        font-style: italic;
        margin: 0 0 25px;
      }

      .text {
        font-size: 16px;
        color: var(--theme-color12);

        sup {
          display: none;
        }
      }

      p {
        display: none;
      }

      blockquote {
        display: none;
      }

      p:nth-child(1) {
        display: block;
        margin: 0;
      }

      .link {
        margin-top: 20px;
        font-size: 18px;
        color: var(--theme-color-main);
      }

      a {
        position: relative;
      }

      a::after {
        content: '';
        position: absolute;
        bottom: -4px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: var(--theme-color-main);
        opacity: 0;
        transition: opacity 300ms, transform 300ms;
      }

      a:hover::after{
        opacity: 1;
        transform: translate3d(0, 0.2em, 0);
      }

      a:after {
        opacity: 1;
        transform: scale(0);
        transform-origin: center;
      }

      a:hover::after {
        transform: scale(1);
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .wrapper {

    .subWrapper {
      padding: 0 40px;
    }
  }
}

@media screen and (max-width: 800px) {
  .wrapper {

    .subWrapper {

      .title {
        font-size: 22px;
        margin-top: 25px;
        padding-bottom: 15px;
        border-bottom: 9px solid var(--theme-color-main);
      }

      .container {
        padding: 30px 0;

        .author {
          font-size: 18px;
          margin-bottom: 20px;
        }

        .tractateTitle {
          font-size: 22px;
          margin: 0 0 20px;
        }

        .text {
          font-size: 14px;
        }

        .link {
          margin-top: 15px;
          font-size: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 620px) {
  .wrapper {

    .subWrapper {

      .title {
        margin-top: 25px;
        text-align: center;
        justify-content: center;
        border-bottom: 6px solid var(--theme-color-main);
      }
    }
  }
}

@media screen and (max-width: 520px) {
  .wrapper {

    .subWrapper {
      padding: 0 30px;

      .title {
        font-size: 18px;
        margin-top: 20px;
        padding-bottom: 10px;
      }

      .container {
        padding: 20px 0;

        .author {
          font-size: 16px;
        }

        .tractateTitle {
          font-size: 18px;
          margin: 20px 0;
        }

        .text {
          font-size: 14px;
        }

        .link {
          margin-top: 15px;
          font-size: 16px;
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .wrapper {

    .subWrapper {
      padding: 0 20px;
    }
  }
}