#root {
  height: 100%;
  display: flex;
  flex-flow: column;
}

.light {
  --theme-color-main: #da9077;
  --theme-page-background: #ffffff;
  --theme-color1: #000000;
  --theme-color2: #ffffff;
  --theme-color3: #cfcfcf;
  --theme-color4: #858585;
  --theme-color5: #999999;
  --theme-color6: #e0e0e0;
  --theme-color7: #ead9c5;
  --theme-color8: #eaeaea;
  --theme-color9: #cfcfcf;
  --theme-color10: #ffffff;
  --theme-color11: #d0d0d0;
  --theme-color12: #000000;
  --theme-color13: #cfcfcf;
  --theme-color14: #eaeaea;
  --theme-color15: #dfdfdf;
  --theme-color16: #ead9c5;
  --theme-box-shadow: -3.33px 2.5px 6.5px rgba(51,50,51,0.25);
  --theme-background-transparent: rgba(102, 102, 102, 0.95);
  --tractatePointAfterOp : 0;
}

.dark {
  --theme-color-main: #000000;
  --theme-page-background: #ffffff;
  --theme-color1: #ffffff;
  --theme-color2: #000000;
  --theme-color3: #000000;
  --theme-color4: #858585;
  --theme-color5: #000000;
  --theme-color6: #ffffff;
  --theme-color7: #ead9c5;
  --theme-color8: #000000;
  --theme-color9: #ffffff;
  --theme-color10: #ffffff;
  --theme-color11: #000000;
  --theme-color12: #000000;
  --theme-color13: #000000;
  --theme-color14: #ffffff;
  --theme-color15: #000000;
  --theme-color16: #000000;
  --theme-box-shadow: unset;
  --theme-background-transparent: rgba(255,255,255, 1);
  --tractatePointAfterOp : 1;
}