.wrapper {
  width: 100%;

  .subSubWrapper {
    width: 100%;
    max-width: 940px;
    padding: 0 60px;
    margin: 2.60vw auto 0;
    position: relative;

    a {
      word-break: break-word;
      color: var(--theme-color12);
      text-decoration: underline;
    }

    p {
      font-size: 20px;
    }

    ul {
      font-size: 20px;
      list-style-type: square;
    }
  }
}

.subWrapper {
  display: flex;
  flex-flow: column;
}

@media screen and (max-width: 1400px) {
  .wrapper {

    .subSubWrapper {
      padding: 0 40px;
    }
  }
}

@media screen and (max-width: 800px) {
  .wrapper {

    .subSubWrapper {
      p {
        font-size: 18px;
      }

      ul {
        font-size: 18px;
      }
    }
  }
}

@media screen and (max-width: 520px) {
  .wrapper {

    .subSubWrapper {
      padding: 0 30px;

      p {
        font-size: 16px;
      }

      ul {
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .wrapper {

    .subSubWrapper {
      padding: 0 20px;
    }
  }
}