.loader{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    z-index: 100000000;
    background-color: white;
    flex-flow: column;
    opacity: 1;
    visibility: visible;
    transition: 0.4s all;
    cursor: pointer;

    .loaderInner {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;
        width: 100px;
        margin-left: calc(50vw - 50px);

    }

    .circle {
        position: absolute;
        border: 3px solid transparent;
        border-top-color: var(--theme-color-main);
        border-radius: 50%;
        animation: rotate linear infinite;
    }

    .one {
        height: 40px;
        width: 40px;
        animation-duration: 0.80s
    }


    .two {
        height: 60px;
        width: 60px;
        animation-duration: 0.90s;
    }


    .three {
        height: 80px;
        width: 80px;
        animation-duration: 1s;
    }

    .four {
        height: 100px;
        width: 100px;
        animation-duration: 1.10s
    }

    .five {
        height: 120px;
        width: 120px;
        animation-duration: 1.20s
    }


    @keyframes rotate {
        0% {
            transform: rotate(0);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}

.loaderHide {
    opacity: 0;
}