html  {
  height: 100%;
  /*overflow: hidden;
  width: 100%;*/
}

body {
  height: 100%;
  display: flex;
  margin: 0 auto;
  max-width: 1920px;
  -webkit-tap-highlight-color: transparent;
  /*width: 100%;
  position: fixed;*/
  /* prevent overscroll bounce*/
  /*overflow-y: scroll;
  -webkit-overflow-scrolling: touch;*/
  /* iOS velocity scrolling */
}

* {
  outline: 0;
  box-sizing: border-box;
  line-height: 1.2;
  font-family: "Roboto", sans-serif;
}

*:hover {
  outline: 0;
}

*:focus {
  outline: 0;
}

a {
  outline: 0;
  text-decoration: unset;
}

a:hover, a:active, a:focus {
  outline: 0;
}

input {
  outline: 0;
}

input:hover, input:active, input:focus {
  outline: 0;
}

button {
  outline: 0;
}

button:hover, button:active, button:focus {
  outline: 0;
}

@font-face {
  font-family: 'Bodoni Moda';
  src: url('../fonts/Bodoni/BodoniModa-Italic.eot');
  src: url('../fonts/Bodoni/BodoniModa-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Bodoni/BodoniModa-Italic.woff2') format('woff2'),
  url('../fonts/Bodoni/BodoniModa-Italic.woff') format('woff'),
  url('../fonts/Bodoni/BodoniModa-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Regular.eot');
  src: url('../fonts/Roboto/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto/Roboto-Regular.woff2') format('woff2'),
  url('../fonts/Roboto/Roboto-Regular.woff') format('woff'),
  url('../fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Italic.eot');
  src: url('../fonts/Roboto/Roboto-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto/Roboto-Italic.woff2') format('woff2'),
  url('../fonts/Roboto/Roboto-Italic.woff') format('woff'),
  url('../fonts/Roboto/Roboto-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Medium.eot');
  src: url('../fonts/Roboto/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto/Roboto-Medium.woff2') format('woff2'),
  url('../fonts/Roboto/Roboto-Medium.woff') format('woff'),
  url('../fonts/Roboto/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-MediumItalic.eot');
  src: url('../fonts/Roboto/Roboto-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto/Roboto-MediumItalic.woff2') format('woff2'),
  url('../fonts/Roboto/Roboto-MediumItalic.woff') format('woff'),
  url('../fonts/Roboto/Roboto-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-BoldItalic.eot');
  src: url('../fonts/Roboto/Roboto-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto/Roboto-BoldItalic.woff2') format('woff2'),
  url('../fonts/Roboto/Roboto-BoldItalic.woff') format('woff'),
  url('../fonts/Roboto/Roboto-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto/Roboto-Bold.eot');
  src: url('../fonts/Roboto/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Roboto/Roboto-Bold.woff2') format('woff2'),
  url('../fonts/Roboto/Roboto-Bold.woff') format('woff'),
  url('../fonts/Roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

