.wrapper {
  width: 100%;

  .listWrapper {
    display: flex;
    flex-flow: column;
    width: 100%;
    max-width: 940px;
    padding: 0 60px;
    margin: 35px auto 0;

    .title {
      font-size: 24px;
      color: var(--theme-color12);
      width: 100%;
      border-bottom: 12px solid var(--theme-color-main);
      padding-bottom: 25px;
      transition: 0.3s all;
      display: flex;
    }

    .container {
      display: flex;
      padding: 15px 0;
      border-bottom: 3px dotted var(--theme-color5);
      transition: 0.3s all;
      width: 100%;
      align-items: center;
      justify-content: flex-start;

      &:hover .name{
        background-size: 0 2px, 100% 2px;
      }

      .imageWrapper {
        display: flex;
        transition: background 0.3s;
        align-items: center;
        justify-content: center;
        width: 163px;
        height: 189px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        clip-path: polygon(50% 0, 100% 26%, 100% 74%, 50% 100%, 0 74%, 0 26%);
        background-color: var(--theme-color-main);
        margin: 0 50px;
        flex-shrink: 0;

        img {
          z-index: 1000;
          max-width: 95px;
        }

        .uni {
          max-width: 108px;
        }

        .creator_1 {
          max-width: 109px;
          margin-left: 17px;
        }

        .creator_2 {
          max-width: 100px;
          margin-right: 5px;
        }

        .creator_3 {
          max-width: 95px;
        }

        .creator_4 {
          max-width: 125px;
        }

      }

      .imageWrapper:before {
        content: '';
        position: absolute;
        top: 5px;
        left: 5px;
        clip-path: polygon(50% 0, 100% 26%, 100% 74%, 50% 100%, 0 74%, 0 26%);
        background-color: white;
        width: 153px;
        height: 179px;
      }

      p {
        margin: 0;
        text-align: left;
      }

      .name {
        font-size: 22px;
        color: var(--theme-color12);
        font-weight: 500;
        text-align: left;
        position: relative;
        background: linear-gradient(to right, rgb(255, 255, 255), rgb(255, 255, 255)), linear-gradient(to right, var(--theme-color12), var(--theme-color12));
        background-size: 100% 2px, 0 2px;
        background-position: 100% 100%, 0 100%;
        background-repeat: no-repeat;
        transition: background-size 400ms;
        padding-bottom: 3px;
        line-height: 1.3;
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .wrapper {

    .listWrapper {
      padding: 0 40px;
    }
  }
}

@media screen and (max-width: 800px) {
  .wrapper {

    .listWrapper {
      margin-top: 15px;

      .container {
        padding: 10px 0;

        .imageWrapper {
          height: 23vw;
          width: 20.37vw;

          img {
            max-width: 11.875vw;
          }

          .uni {
            max-width: 13.5vw;
          }

          .creator_1 {
            max-width: 13.625vw;
            margin-left: 2.125vw;
          }

          .creator_2 {
            max-width: 12.5vw;
            margin-right: 0.625vw;
          }

          .creator_3 {
            max-width: 11.875vw;
          }

          .creator_4 {
            max-width: 15.625vw;
          }
        }

        .imageWrapper:before {
          height: 21.7vw;
          width: 19.13vw;
          top: 0.62vw;
          left: 0.62vw ;
        }
      }

    }
  }
}

@media screen and (max-width: 620px) {
  .wrapper {

    .listWrapper {

      .title {
        font-size: 21px;
        border-bottom: 8px solid var(--theme-color-main);
        padding-bottom: 18px;
      }

      .container {

        .imageWrapper {
          height: 141px;
          width: 125px;
          margin: 0 25px;

          img {
            max-width: 73px;
          }

          .uni {
            max-width: 83px;
          }

          .creator_1 {
            max-width: 84px;
            margin-left: 13px;
          }

          .creator_2 {
            max-width: 77px;
            margin-right: 4px;
          }

          .creator_3 {
            max-width: 77px;
          }

          .creator_4 {
            max-width: 97px;
          }
        }

        .imageWrapper:before {
          height: 133px;
          width: 118px;
          top: 4px;
          left: 4px;
        }

        .name {
          font-size: 19px;
        }
      }

    }
  }
}

@media screen and (max-width: 450px) {
  .wrapper {

    .listWrapper {
      margin-top: 10px;

      .title {
        font-size: 19px;
        border-bottom: 6px solid var(--theme-color-main);
        padding-bottom: 15px;
      }

      .container {

        .imageWrapper {
          height: 112px;
          width: 100px;
          margin: 0 10px;

          img {
            max-width: 58px;
          }

          .uni {
            max-width: 66px;
          }

          .creator_1 {
            max-width: 67px;
            margin-left: 10px;
          }

          .creator_2 {
            max-width: 62px;
            margin-right: 3px;
          }

          .creator_3 {
            max-width: 62px;
          }

          .creator_4 {
            max-width: 78px;
          }
        }

        .imageWrapper:before {
          height: 106px;
          width: 94px;
          top: 3px;
          left: 3px;
        }

        p {
          text-align: center;
        }

        .name {
          font-size: 17px;
          text-align: center;
          margin: 0 auto;
        }
      }

    }
  }
}

@media screen and (max-width: 520px) {
  .wrapper {

    .listWrapper {
      padding: 0 30px;
    }
  }
}

@media screen and (max-width: 400px) {
  .wrapper {

    .listWrapper {
      padding: 0 20px;
    }
  }
}

@media screen and (max-width: 355px) {
  .wrapper {

    .listWrapper {

      .container {

        .imageWrapper {
          height: 100px;
          width: 90px;
          margin: 0 10px;

          img {
            max-width: 53px;
          }

          .uni {
            max-width: 61px;
          }

          .creator_1 {
            max-width: 62px;
            margin-left: 8px;
          }

          .creator_2 {
            max-width: 57px;
            margin-right: 2px;
          }

          .creator_3 {
            max-width: 57px;
          }

          .creator_4 {
            max-width: 73px;
          }
        }

        .imageWrapper:before {
          height: 94px;
          width: 84px;
          top: 3px;
          left: 3px;
        }

        .name {
          font-size: 15px;
          text-align: center;
          justify-content: center;
        }
      }

    }
  }
}