.wrapper {
  flex-flow: column;

  .quoteMainTitle {
    font-family: "Roboto", sans-serif !important;
    font-size: 28px;
    margin-bottom: 30px;
  }

  .quoteWrapper {
    display: flex;
    width: 100%;
    margin-top: 30px;
    flex-flow: column;

    .title {
      font-size: 20px;
      font-weight: 500;
      font-family: "Roboto", sans-serif !important;
    }

    .quoteContainer {
      display: flex;
      align-items: flex-start;
      position: relative;

      .quote {
        background-color: #ffffff;
        width: 100%;
        word-break: break-word;
        border: 2px solid var(--theme-color-main);
        padding: 5px;
        transition: 0.3s all;


        p {
          margin: 0;
          font-family: "Roboto";
        }
      }

      .copy {
        background-color: var(--theme-color-main);
        margin-left: 30px;
        font-size: 20px;
        font-weight: 500;
        font-family: "Roboto", sans-serif !important;
        transition: 0.3s all;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 10px;
        cursor: pointer;
        user-select: none;
        position: relative;
        border: 2px solid var(--theme-color-main);

        &:hover {
          background-color: #ffffff;
          color: var(--theme-color-main);
          border: 2px solid var(--theme-color-main);
        }

        .before {
          position: absolute;
          background-color: rgba(0,0,0,0.8);
          padding: 3px 8px;
          font-size: 16px;
          border-radius: 5px;
          top: -100%;
          font-weight: 400;
          cursor: default;
          opacity: 0;
          transition: 0.3s all;
          color: #ffffff;

          &:after {
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -8px;
            width: 0;
            height: 0;
            border-top: 8px solid rgba(0,0,0,0.8);
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
          }
        }

        .beforeShow {
          opacity: 1;
        }
      }
    }
  }

  .imgPhoto {
    max-width: 100%;
    height: auto;
  }

  .titleWrapper {
    display: flex;
    flex-flow: column;
    padding: 0 60px 0;
    margin: 0 auto;
    max-width: 1480px;
    width: 100%;
    align-items: flex-end;

    .author {
      font-size: 24px;
      color: var(--theme-color-main);
      margin-right: 4px;
      white-space: nowrap;
      flex-direction: row;
      transition: 0.3s color;
    }

    .title {
      font-size: 24px;
      color: var(--theme-color12);
      font-weight: 500;
      text-align: left;
    }
  }

  .mobileContents {
  }

  .phoneContentsWrapper {
    display: none;
    flex-flow: column;
    width: 100%;
    border-top: 6px solid var(--theme-color-main);
    border-bottom: 6px solid var(--theme-color-main);
  }

  .stickyWrapper {

  }

  .contentsOpen {
    display: none;
    margin-right: auto;
    width: 60px;
    height: 60px;
    position: absolute;
    top: -2px;
    left: -60px;
    align-items: center;
    justify-content: center;
    background-color: var(--theme-color10);
    border: 2px solid var(--theme-color13);
    color: white;

    .btn {
      width: 37px;
      cursor: pointer;
      flex-shrink: 0;
      z-index: 10000;
    }

    span {
      display: block;
      width: 100%;
      height: 3px;
      background: var(--theme-color13);
      transition: all .3s;
      position: relative;
      border-radius: 10px;

      + span {
        margin-top: 9px;
        animation: top1 .5s forwards;
        animation-timing-function: ease;
      }
    }

    .active span:nth-child(1) {
      animation: top1 .5s forwards;
    }

    .notActive span:nth-child(1) {
      animation: top2 .5s forwards;
    }

    .active span:nth-child(2) {
      animation: scaled1 .5s  forwards;
    }

    .notActive span:nth-child(2) {
      animation: scaled2 .5s  forwards;
    }

    .active span:nth-child(3) {
      animation: bottom1 .5s  forwards;
    }

    .notActive span:nth-child(3) {
      animation: bottom2 .5s  forwards;
    }

    @keyframes top1 {
      0% {
        top: 0;
        transform: rotate(0);
      }

      50% {
        top: 12px;
        transform: rotate(0);
      }

      100% {
        top: 12px;
        transform: rotate(45deg);
      }
    }

    @keyframes top2 {
      0% {
        top: 12px;
        transform: rotate(45deg);
      }

      50% {
        top: 12px;
        transform: rotate(0deg);
      }

      100% {
        top: 0;
        transform: rotate(0deg);
      }
    }

    @keyframes bottom1 {
      0% {
        bottom: 0;
        transform: rotate(0);
      }

      50% {
        bottom: 12px;
        transform: rotate(0);
      }

      100% {
        bottom: 12px;
        transform: rotate(135deg);
      }
    }

    @keyframes bottom2 {
      0% {
        bottom: 12px;
        transform: rotate(135deg);
      }

      50% {
        bottom: 12px;
        transform: rotate(0);
      }

      100% {
        bottom: 0;
        transform: rotate(0);
      }
    }

    @keyframes scaled1 {
      50% {
        transform: scale(0);
      }

      100% {
        transform: scale(0);
      }
    }

    @keyframes scaled2 {
      0% {
        transform: scale(0);
      }

      50% {
        transform: scale(0);
      }

      100% {
        transform: scale(1);
      }
    }
  }

  .spisWrapper {
    display: none;
    transition: height 0.4s ease-in-out;
    width: calc(100% - 18px);
    margin: 0 auto;
    user-select: none;
  }

  .spisWrapperOpen {

  }

  .pointWrapper {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    padding-right: 4px;
    padding-left: 4px;

    .pointParagraph {
      margin: 0;
      text-align: left;
      position: relative;
    }

    .pointContent {
      display: flex;
      cursor: pointer;
      margin: 6px 0;
      align-items: center;

      .pointText {
        font-size: 12px;
        color: var(--theme-color12);
        font-weight: 500;
        transition: 0.3s all;
        position: relative;
      }

      .pointText:after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: var(--theme-color12);
        opacity: 0;
        transition: opacity 300ms, transform 300ms;
        transform: scale(0);
      }

      .pointArrow {
        transition: 0.4s all;
        color: transparent;
        margin: 5px 0 5px 3px;
        flex-shrink: 0;
        position: relative;
      }

      .pointArrow:after {
        content: '►';
        transition: 0.4s all;
        color: var(--theme-color-main);
        position: absolute;
        left: 0;
      }

      .pointArrowOpen:after {
        transform: rotate(90deg);
      }
    }

    .pointContentActive {

      .pointText {
        color: var(--theme-color-main);
      }

      .pointText:after {
        opacity: var(--tractatePointAfterOp);
        transform-origin: center;
        transform: scale(1);
      }
    }

    .subPoints {
      display: flex;
      flex-flow: column;
      width: calc(100% - 5px);
      margin-left: 5px;
      align-items: flex-start;
      transition: height 0.5s ease-out;
      height: 0;
      overflow: hidden;

      .subPoint {
        cursor: pointer;
        font-size: 12px;
        color: var(--theme-color5);
        transition: 0.3s all;
        margin: 2px 0;
        position: relative;
      }

      .subPoint:after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: var(--theme-color12);
        opacity: 0;
        transition: opacity 300ms, transform 300ms;
        transform-origin: center;
        transform: scale(1);
      }

      .subPointActive {
        color: var(--theme-color-main);
      }

      .subPointActive:after {
        opacity: var(--tractatePointAfterOp);
      }
    }
  }

  .contentsWrapper {
    display: flex;
    flex-flow: column;
    position: absolute;
    top: 0;
    right: -12.23vw;
    height: 100%;
    width: 13.02vw;
    user-select: none;
    transition: top 0s ease;
    z-index: 10;


    .contents {
      position: sticky;
      display: flex;
      flex-flow: column;
      top: 20px;
      margin: 0 10px;
      max-height: calc(100vh - 40px);
      overflow-y: auto;

      .title {
        font-size: 14px;
        font-style: italic;
        color: var(--theme-color12);
        font-weight: 500;
        margin-bottom: 8px;
        transition: 0.3s all;
      }
    }

    .contents::-webkit-scrollbar-thumb {
      background: var(--theme-color-main);
      width: 8px;
      margin: 10px 0;
    }

    .contents::-webkit-scrollbar-corner {
      background: #ebecec;
    }

    .contents::-webkit-scrollbar {
      background: #cfcfcf;
      width: 10px;
      border: 3px solid white;
    }
  }

  .contentsWrapperActive {
    width: 0;
  }

  .tractateWrapper {
    width: 100%;
    max-width: 86.71vw;
    padding: 0 25px;
    display: flex;
    margin-right: auto;
    margin-top: 30px;
    position: relative;
    justify-content: center;

    .tractate {
      width: calc((100% - 20px) / 2);
      height: calc(100vh - 40px);
      min-height: 500px;
      transition: 0.3s all;
      display: flex;
      flex-flow: column;
      position: relative;

      .tractateTitleWrapper {
        width: 100%;
        display: flex;
        justify-content: right;
        transition: 0.3s all;

        .tractateTitle {
          background-color: var(--theme-color10);
          font-size: 16px;
          padding: 10px 25px;
        }
      }

      .emptyTractateWrapper {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        color: var(--theme-color12);
        background-color: var(--theme-color14);
      }

      .tractateMenu {
        display: flex;
        justify-content: left;
        margin-left: 15px;
        margin-top: 10px;

        .menuItem {
          clip-path: polygon(0 75%, 0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%);
          width:40px;
          height:45.97px;
          background-color: var(--theme-color16);
          margin-right: 6px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: 0.3s all;

          .menuImage {
            width: 40px;
            height: 40px;
            background-position: center;
            background-size: auto 21px;
            background-repeat: no-repeat;
            transition: 0.3s all;
          }
        }
      }

      .contentWrapper {
        display: flex;
        flex-flow: column;
        width: 98.7%;
        margin: 20px 1.3% 20px 0;
        overflow: auto;
        padding: 0 5% 0 6%;
        order: 2;
        position: relative;

        .content {
          display: flex;
          width: 100%;
          position: relative;
          flex-flow: column;
          margin: 0 auto;

          .tooltipWrapper {
            line-height: 0 !important;

            .tooltip {
              visibility: hidden;
              max-width: 400px;
              min-width: 200px;
              background-color: var(--theme-color10);
              color: var(--theme-color12);
              text-align: center;
              position: absolute !important;
              z-index: 10;
              opacity: 0;
              transition: opacity 0.3s, visibility 0.2s !important;
              padding: 15px;
              box-shadow: 0 0 6.5px rgba(103,102,103,0.55);
              cursor: default;
              display: flex;
              align-items: center;
              justify-content: center;
              width: max-content;
              width: -moz-max-content;
              width: -webkit-max-content;
              height: auto;

              a {
                color: var(--theme-color-main);
                text-decoration: underline;
                word-break: break-word;
              }
            }

            .descLink {
              font-size: 12px;
              color: var(--theme-color12);
              padding: 0 2px;
              font-family: "Roboto", sans-serif !important;
            }
          }

          .tooltipWrapper:hover .tooltip {
            visibility: visible;
            opacity: 1;
            transition: opacity 0.3s, visibility 0.4s !important;
          }

          .chapter {
            display: flex;
            width: 100%;
            flex-flow: column;

            img {
              max-width: 100%;
              height: auto;
            }

            p {
              position: relative;

              sup {
                a {
                  word-break: break-word;
                }
              }
            }

            span {
              position: relative;
            }

            strong {
              position: relative;
            }

            .subChapter {
              display: flex;
              width: 100%;
              flex-flow: column;
            }

            .pageImage {
              width: 100%;
            }
          }

          .bibliography {
            border-top: 2px dashed var(--theme-color9);
            margin-top: 40px;
            padding-top: 40px;
            flex-flow: column;
            word-break: break-word;

            p{
              position: relative;
              font-size: 16px;
              line-height: 1.2 ;
              font-family: "Roboto", sans-serif !important;
              margin: 3px 0 3px 50px;
            }

            span {
              font-size: 16px;
              line-height: 1.2;
              font-family: "Roboto", sans-serif !important;
            }

            a {
              color: var(--theme-color-main);
              text-decoration: underline;
              word-break: break-word;
            }

            .bibliographyLink {
              display: flex;
              justify-content: flex-start;
              position: absolute;
              left: -50px;
              font-size: 16px;
              line-height: 1.2;
              color: var(--theme-color12) !important;
              font-family: "Roboto", sans-serif !important;
              text-decoration: unset;
            }
          }
        }
      }

      .contentWrapperPDF{
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
      }

      .pdfContainer{
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
      }

      .contentWrapper::-webkit-scrollbar-thumb {
        background: var(--theme-color-main);
        width: 8px;
        height: 8px;
        margin: 10px 0;
        transition: 0.3s background;
      }

      .contentWrapper::-webkit-scrollbar-corner {
        background: #ebecec;
      }

      .contentWrapper::-webkit-scrollbar {
        background: #cfcfcf;
        width: 10px;
        height: 10px;
        border: 3px solid white;
      }
    }

    .left {
      border: 3px solid var(--theme-color3);

      .tractateTitleWrapper {
        background-color: var(--theme-color3);
      }
    }

    .right {
      border: 3px solid var(--theme-color-main);
      margin-left: 20px;
      transition: 0.3s border;

      .tractateTitleWrapper {
        background-color: var(--theme-color-main);
        transition: 0.3s background;
      }
    }

    .empty {
      display: none;
    }

    .fullWidth {
      width: 100%;
      max-width: 1200px;
    }
  }
}

@media screen and (min-width: 1920px) {
  .wrapper {
    .contentsWrapper {
      max-width: 245px;
      right: -235px;
    }
  }
}

@media screen and (max-width: 1600px) {
  .wrapper{

    .mobileContents {
      display: flex;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
    }

    .contentsWrapper {
      background-color: white;
      position: sticky;
      border: 2px solid transparent;
      border-right: unset;
      transition: width 0.6s ease-in-out, border 0.4s ease-in-out;
      top: 0;
      width: 0;
      padding-bottom: 20px;

      .contents {
        margin-left: unset;
        margin-right: unset;
        margin-top: -2px;
        opacity: 0;
        transition: 0.4s ease-in-out;
        overflow-x: hidden;

        .title {
          width: 205px;
          flex-shrink: 0;
          display: flex;
        }

        .pointWrapper {
          width: 205px;
          flex-shrink: 0;
          display: flex;
        }
      }

      .contentsOpen {
        display: flex;
        margin-left: -60px;
        margin-top: -2px;
        position: sticky;
        flex-shrink: 0;
      }
    }

    .contentsWrapperActive {
      width: 280px;
      border: 2px solid var(--theme-color13);
      border-right: unset;


      .contents {
        margin-left: 35px;
        padding-right: 35px;
        opacity: 1;
        transition: 1.5s;
      }
    }

    .tractateWrapper {

      .tractate {

        .tractateTitleWrapper {
          justify-content: left;
        }

      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .wrapper {

    .titleWrapper {
      padding: 0 40px 0;
    }

    .tractateWrapper {
      padding: 0 40px 0;
    }
  }
}

@media screen and (max-width: 1050px) {
  .wrapper {

    .tractateWrapper {


      .tractate {

        .contentWrapper {

          .content {

            .tooltipWrapper {

              .tooltip {
                max-width: 100%;

              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .wrapper {

    .titleWrapper {
      align-items: center;

      .title {
        text-align: center;
        font-size: 22px;
      }

      .author {
        text-align: center;
        font-size: 20px;
        margin-top: 15px;
        margin-bottom: 5px;
        margin-right: unset;
      }
    }

    .tractateWrapper {

      .left {
        width: 100%;
      }

      .right {
        display: none
      }

      .tractate {

        .contentWrapper {

          .content {

            .tooltipWrapper {

              .tooltip {
                max-width: 400px;

              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 560px) {
  .wrapper {

    .tractateWrapper {


      .tractate {

        .contentWrapper {

          .content {

            .tooltipWrapper {

              .tooltip {
                max-width: 100%;

              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 520px) {
  .wrapper {

    .titleWrapper {
      padding: 0 30px 0;
    }

    .tractateWrapper {
      padding: 0 30px 0;
    }
  }
}

@media screen and (max-width: 450px) {
  .wrapper {

    .stickyWrapper {
      height: 72px;
      position: sticky;
      z-index: 100;
      top: 0;
    }

    .phoneContentsWrapper {
      display: flex;
      position: sticky;
      top: 0;
      background-color: var(--theme-color10);
      z-index: 1000;
      margin: 0 30px;
      width: calc(100% - 60px);
    }

    .spisWrapper {
      flex-flow: column;
      max-height: calc(100vh - 72px);
      padding: 0 5px;
    }

    .spisWrapper::-webkit-scrollbar-thumb {
      background: var(--theme-color-main);
      width: 8px;
      margin: 10px 0;
    }

    .spisWrapper::-webkit-scrollbar-corner {
      background: #ebecec;
    }

    .spisWrapper::-webkit-scrollbar {
      background: #cfcfcf;
      width: 10px;
      border: 3px solid white;
    }

    .spisWrapperOpen {
      display: flex;
      overflow-y: hidden;
    }

    .pointWrapper {
      align-items: center;

      .pointParagraph {
        text-align: center;
      }

      .pointContent {
        margin: 10px 0;

        .pointText{
          font-size: 16px;
          font-weight: unset;
        }
      }

      .subPoints {
        align-items: center;

        .subPoint {
          font-size: 14px;
        }
      }
    }

    .pointWrapper:last-child {
      margin-bottom: 20px;
    }

    .contentsOpen {
      display: flex;
      position: relative;
      left: unset;
      top: unset;
      border: unset;
      margin: 0 auto;

      .spis {
        color: var(--theme-color-main);
        display: flex;
        flex-shrink: 0;
        font-size: 18px;
        margin-right: 10px;
      }

      .btn {
        width: 35px;
        cursor: pointer;
        flex-shrink: 0;
        z-index: 10000;
      }

      span {
        display: block;
        width: 100%;
        height: 4px;
        background: var(--theme-color-main);
        transition: all .3s;
        position: relative;
        border-radius: 10px;

        + span {
          margin-top: 5px;
        }
      }

      @keyframes top1 {
        0% {
          top: 0;
          transform: rotate(0);
        }

        50% {
          top: 9px;
          transform: rotate(0);
        }

        100% {
          top: 9px;
          transform: rotate(45deg);
        }
      }

      @keyframes top2 {
        0% {
          top: 9px;
          transform: rotate(45deg);
        }

        50% {
          top: 9px;
          transform: rotate(0deg);
        }

        100% {
          top: 0;
          transform: rotate(0deg);
        }
      }

      @keyframes bottom1 {
        0% {
          bottom: 0;
          transform: rotate(0);
        }

        50% {
          bottom: 9px;
          transform: rotate(0);
        }

        100% {
          bottom: 9px;
          transform: rotate(135deg);
        }
      }

      @keyframes bottom2 {
        0% {
          bottom: 9px;
          transform: rotate(135deg);
        }

        50% {
          bottom: 9px;
          transform: rotate(0);
        }

        100% {
          bottom: 0;
          transform: rotate(0);
        }
      }

      @keyframes scaled1 {
        50% {
          transform: scale(0);
        }

        100% {
          transform: scale(0);
        }
      }

      @keyframes scaled2 {
        0% {
          transform: scale(0);
        }

        50% {
          transform: scale(0);
        }

        100% {
          transform: scale(1);
        }
      }
    }

    .mobileContents {
      display: none;
    }

    .tractateWrapper {
      margin-top: 25px;

      .tractate {
        border: unset;
        height: auto;

        .tractateTitleWrapper {
          order: 1;
          background-color: unset;

          .tractateTitle {
            width: 100%;
            text-align: center;
            background-color: var(--theme-color-main);
            color: var(--theme-color10);
            padding: 15px;
          }
        }

        .tractateMenu {
          order: 0;
          width: 100%;
          margin: 0 0 25px 0;
          justify-content: center;

          .menuItem {
            width:52px;
            height:59.761px;
            margin: 0 5px;

            .menuImage {
              background-size: auto 30px;
            }
          }
        }

        .contentWrapper {
          display: flex;
          flex-flow: column;
          width: 100%;
          margin: 20px 0;
          overflow: auto;
          height: 100%;
          padding: 0;
          order: 2;

          .content {

            .tooltipWrapper {
              line-height: 0;

              .tooltip {
                display: none;
                max-width: calc(100vw - 60px);
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .wrapper {

    .titleWrapper {
      padding: 0 20px 0;
    }

    .tractateWrapper {
      padding: 0 20px 0;
      margin-top: 20px;

      .tractate {

        .tractateMenu {
          margin: 0 0 20px 0;

          .menuItem {
            width:46.8px;
            height:53.7849px;

            .menuImage {
              background-size: auto 25px;
            }
          }
        }

        .contentWrapper {

          .content {

            .tooltipWrapper {

              .tooltip {
                max-width: calc(100vw - 40px);
              }
            }
          }
        }
      }
    }

    .phoneContentsWrapper {
      margin: 0 20px;
      width: calc(100% - 40px);
    }
  }
}