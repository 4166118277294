.footerWrapper {
  width: 100%;
  transition: 0.3s all;
  margin-top: auto;
  padding-top: 10px;

  .titleWrapper {
    margin-top: auto;

    .title {
      font-size: 16px;
      color: var(--theme-color5);
      transition: 0.3s all;
      margin: 50px 0 10px;
      padding: 0 60px;
      width: 100%;
      text-align: center;
      display: flex;
      align-items: flex-end;
    }
  }
}

.footerSubWrapper {
  display: flex;
  flex-flow: column;
  align-items: center;
}


.footerSubSubWrapper {
  display: flex;
  width: 100%;
  background-color: var(--theme-color8);
  transition: 0.3s all;

  .footer {
    max-width: 1480px;
    width: 100%;
    display: flex;
    margin: 0 auto;
    padding: 35px 60px 60px;

    .leftWrapper {
      display: flex;
      flex-flow: column;
      transition: 0.3s all;
      padding: 10px 20px 10px 0;
      margin-right: auto;

      .leftTop {
        display: flex;
        max-width: 820px;
        align-items: center;
        justify-content: space-between;

        .item {
          display: flex;
          flex-flow: column;
          align-items: center;
          height: 100%;

          .description {
            font-size: 11px;
            color: var(--theme-color1);
            font-family: "Roboto", sans-serif;
            font-weight: 500;
            margin-bottom: 35px;
            transition: 0.3s all;
          }

          img {
            margin: auto 0;
            transition: 0.3s all;
          }

          .smaller {
            max-width: 240px;
          }
        }

        .item:nth-child(1) {

          img {
            max-width: 140px;
          }
        }

        .item:nth-child(2) {
          margin: 0 5px 0 10px;

          img {
            max-width: 90px;
          }
        }

        .item:nth-child(3) {
          margin: 0 10px 0 5px;

          img {
            max-width: 100px;
          }
        }
      }

      .leftBottom {
        display: flex;
        margin-top: 45px;

        .descriptionBottom {
          font-size: 16px;
          color: var(--theme-color1);
          transition: 0.3s all;
          font-family: "Roboto", sans-serif;
          max-width: 820px;
          text-align: justify;
        }
      }
    }

    .separator {
      border-right: 2px dashed #ffffff;
    }

    .rightWrapper {
      display: flex;
      flex-flow: column;
      padding: 10px 0 10px 20px;
      margin-left: auto;
      flex-shrink: 0;

      .rightTop {
        display: flex;
        margin-bottom: 35px;
        align-self: flex-end;
        max-width: 145px;
        width: 100%;
        justify-content: space-between;

        img {
          width: 37px;
        }
      }

      .rightBottom {
        display: flex;
        flex-flow: column;

        .description {
          font-size: 20px;
          line-height: 30px;
          color: var(--theme-color1);
          transition: 0.3s all;
          font-family: "Roboto", sans-serif;
        }

        .description2 {
          position: relative;
        }

        a.description2::after {
          content: '';
          position: absolute;
          bottom: -3px;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: var(--theme-color1);
          opacity: 0;
          transition: opacity 300ms, transform 300ms;
        }

        a.description2:hover::after{
          opacity: 1;
          transform: translate3d(0, 0.2em, 0);
        }

        a.description2:after {
          opacity: 1;
          transform: scale(0);
          transform-origin: center;
        }

        a.description2:hover::after {
          transform: scale(1);
        }
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .footerWrapper {

    .titleWrapper {

      .title {
        padding: 0 40px;
      }
    }
  }

  .footerSubSubWrapper {

    .footer {
      padding: 35px 40px 40px;
    }
  }
}

@media screen and (max-width: 1100px) {
  .footerWrapper {

    .titleWrapper {

      .title {
        margin: 30px 0 10px;
      }
    }
  }

  .footerSubSubWrapper {

    .footer {
      flex-flow: column;

      .leftWrapper {
        order: 2;
        margin: 0 auto;
        width: 100%;
        text-align: center;
        align-items: center;
        padding-right: unset;

        .leftTop {
          width: 100%;
          max-width: 800px;
        }

        .leftBottom {
          width: 100%;
          max-width: 820px;
        }
      }

      .separator {
        order: 1;
        border-bottom: 4px dashed #ffffff;
        max-width: 850px;
        width: 100%;
        margin: 30px auto;
      }

      .rightWrapper {
        order: 0;
        margin: 0 auto;
        text-align: center;
        align-items: center;
        flex-flow: row;
        padding: unset;
        width: 100%;
        justify-content: center;

        .rightTop {
          order: 1;
          flex-shrink: 0;
          margin-bottom: unset;
          align-self: center;
          margin-left: 30px;

          img:nth-child(2) {
            margin: 0 30px;
          }
        }

        .rightBottom {
          order: 0;
          flex-shrink: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .footerSubSubWrapper {

    .footer {

      .leftWrapper {

        .leftTop {
          flex-wrap: wrap;
          align-items: unset;

          .item {
            width: 50%;
            margin: unset;
            height: auto;

            .description {
              margin-bottom: 15px;
            }

            img {
              margin: auto 0 auto 0;
            }

            .smaller {
              width: 100%;
            }
          }

          .item:nth-child(2) {
            margin: 0;
          }

          .item:nth-child(3) {
            margin: 20px 0 0 0 ;
          }

          .item:nth-child(4) {
            margin: 20px 0 0 0 ;
          }
        }

        .leftBottom {
          margin-top: 20px;
        }
      }

      .rightWrapper {
        flex-flow: column;

        .rightTop {
          order: unset;
          margin-left: unset;
          max-width: 180px;

          img {
            width: 46px;
          }
        }

        .rightBottom {
          order: unset;
          border-top: 4px dashed #ffffff;
          width: 100%;
          margin-top: 30px;
          padding-top: 30px;
        }
      }
    }
  }
}

@media screen and (max-width: 520px) {
  .footerWrapper {

    .titleWrapper {

      .title {
        padding: 0 30px;
      }
    }
  }

  .footerSubSubWrapper {

    .footer {
      padding: 35px 30px 30px;

      .leftWrapper {

        .leftTop {

          .item:nth-child(1) {
            order: 0;
            width: 60%;

            img {
              width: 70%;
              max-width: 140px;
            }
          }

          .item:nth-child(2) {
            order: 1;
            width: 40%;
            padding-left: 10px;

            img {
              width: 80%;
              max-width: 92px;
            }
          }

          .item:nth-child(3) {
            order: 4;
            width: 40%;
            padding-left: 10px;

            img {
              width: 80%;
              max-width: 100px;
            }
          }

          .item:nth-child(4) {
            order: 3;
            width: 60%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .footerWrapper {

    .titleWrapper {

      .title {
        padding: 0 20px;
      }
    }
  }

  .footerSubSubWrapper {

    .footer {
      padding: 35px 20px 20px;

      .leftWrapper {

        .leftBottom {

          .descriptionBottom {
            font-size: 13px;
          }
        }
      }

      .rightWrapper {

        .rightBottom {

          .description {
            font-size: 17px;
            line-height: 23px;
          }
        }
      }
    }
  }
}