.wrapper {
  width: 100%;
  margin: 0 auto;

  .subWrapper {
    display: flex;
    flex-flow: column;
    width: 100%;
    max-width: 940px;
    padding: 0 60px;
    margin: 5px auto 0;
    position: relative;

    .polygon {
      display: flex;
      position: absolute;
      left: -213px;
      top: 45px;
      height: 100%;

      .imageWrapper {
        display: flex;
        transition: background 0.3s;
        align-items: center;
        justify-content: center;
        width: 163px;
        height: 189px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        clip-path: polygon(50% 0, 100% 26%, 100% 74%, 50% 100%, 0 74%, 0 26%);
        background-color: var(--theme-color-main);
        margin: 0 50px 50px;
        flex-shrink: 0;
        position: sticky;
        top: 100px;

        img {
          z-index: 1000;
          max-width: 80px;
        }
      }

      .imageWrapper:before {
        content: '';
        position: absolute;
        top: 5px;
        left: 5px;
        clip-path: polygon(50% 0, 100% 26%, 100% 74%, 50% 100%, 0 74%, 0 26%);
        background-color: white;
        width: 153px;
        height: 179px;
      }

      .noMargin {
        margin: unset;
      }
    }

    .title {
      font-size: 24px;
      color: var(--theme-color12);
      width: 100%;
      border-bottom: 12px solid var(--theme-color-main);
      padding-bottom: 25px;
      transition: 0.3s all;
      display: flex;
      margin-top: 30px;
    }

    .noMarginTitle {
    }

    .list {
      display: flex;
      flex-flow: column;
      align-items: center;

      .center {
      }

      .container {
        display: flex;
        padding: 30px 0;
        border-bottom: 3px dotted var(--theme-color5);
        width: 100%;

        .image {
          width: 180px;
          height: auto;
          margin: auto 45px;
          flex-shrink: 0;
        }

        .right {
          display: flex;
          flex-flow: column;
          align-items: flex-start;
          margin: auto 0 auto 35px;

          .author {
            font-size: 20px;
            color: var(--theme-color-main);
            font-weight: 600;
            margin-bottom: 10px;
          }

          .years {
            font-size: 16px;
            color: var(--theme-color5);
          }

          .tractateTitle {
            font-size: 22px;
            color: var(--theme-color12);
            font-weight: 500;
            margin: 25px 0;
          }

          .link {
            margin-top: auto;
            font-size: 16px;
            color: var(--theme-color-main);
            text-decoration: unset;
            display: flex;
            flex-shrink: 0;
            align-items: center;
            word-break: break-word;
            position: relative;

            &::after {
              content: '';
              position: absolute;
              bottom: -3px;
              left: 0;
              width: 100%;
              height: 2px;
              background-color: var(--theme-color-main);
              opacity: 0;
              transition: opacity 300ms, transform 300ms;
            }

            &:hover::after{
              opacity: 1;
              transform: translate3d(0, 0.2em, 0);
            }

            &:after {
              opacity: 1;
              transform: scale(0);
              transform-origin: center;
            }

            &:hover::after {
              transform: scale(1);
            }
          }
        }
      }
    }
  }
}

.fixedText {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  margin-bottom: 20px;
  padding: 0 30px;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;

    span {
      width: 100%;
      text-align: center;
      font-size: 40px;
      font-family: "Bodoni Moda", sans-serif;
      font-style: italic;
    }
  }
}

@media screen and (max-width: 1400px) {
  .wrapper {

    .subWrapper {
      padding: 0 40px;
    }
  }
}

@media screen and (max-width: 1350px) {
  .wrapper {

    .subWrapper {

      .polygon {
        position: relative;
        top: 0;
        left: 0;

        .imageWrapper {
          margin-bottom: unset;
        }
      }

      .title {
        margin-top: 25px;
      }

      .noMarginTitle {
        margin-top: 20px;
      }

      .list {

        .center {
          justify-content: center;
        }
      }
    }
  }
}

@media screen and (max-width: 940px) {
  .wrapper {

    .subWrapper {

      .list {

        .container {

          .right {
            margin-left: unset;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .wrapper {

    .subWrapper {

      .polygon {

        .imageWrapper {
          height: 23vw;
          width: 20.37vw;
          margin: auto 30px;

          img {
            max-width: 10vw;
          }
        }

        .imageWrapper:before {
          height: 21.7vw;
          width: 19.13vw;
          top: 0.62vw;
          left: 0.62vw ;
        }

        .noMargin {
          margin: unset;
        }
      }

      .title {
        font-size: 22px;
        border-bottom: 9px solid var(--theme-color-main);
        padding-bottom: 15px;
        margin-top: 25px;
      }

      .noMarginTitle {
        margin-top: 10px;
      }

      .list {

        .container {

          .image {
            margin: auto 30px;
          }

          .right {

            .tractateTitle {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .wrapper {

    .subWrapper {

      .list {
        display: flex;
        flex-flow: column;
        align-items: center;

        .container {
        }
      }
    }
  }
}

@media screen and (max-width: 620px) {
  .wrapper {

    .subWrapper {

      .polygon {

        .imageWrapper {
          margin: auto 20px;
        }

        .noMargin {
          margin: unset;
        }
      }

      .title {
        font-size: 20px;
        border-bottom: 6px solid var(--theme-color-main);
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .wrapper {

    .subWrapper {

      .list {

        .container {
          display: flex;
          padding: 30px 0;
          border-bottom: 3px dotted var(--theme-color5);
          width: 100%;
          align-items: center;
          flex-flow: column;

          .image {
            width: 30vw;
          }

          .right {
            align-items: center;
            margin-top: 25px;

            .author {
              text-align: center;
              font-size: 18px;
            }

            .years {
              text-align: center;
              font-size: 14px;
            }

            .tractateTitle {
              text-align: center;
              margin: 15px auto;
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 520px) {
  .wrapper {

    .subWrapper {
      padding: 0 30px;

      .polygon {

        .imageWrapper {
          height: 112px;
          width: 100px;
          margin: auto 20px auto 10px;

          img {
            max-width: 48px;
          }
        }

        .imageWrapper:before {
          height: 106px;
          width: 94px;
          top: 3px;
          left: 3px;
        }

        .noMargin {
          margin: unset;
        }
      }
    }
  }

  .fixedText {

    div {

      span {
        font-size: 30px;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .wrapper {

    .subWrapper {
      padding: 0 20px;

      .polygon {

        .imageWrapper {
          height: 100px;
          width: 90px;
          margin: auto 15px auto 0;

          img {
            max-width: 43px;
          }
        }

        .imageWrapper:before {
          height: 94px;
          width: 84px;
          top: 3px;
          left: 3px;
        }

        .noMargin {
          margin: unset;
        }
      }

      .list {

        .container {

          .image {
            width: 120px;
          }
        }
      }
    }
  }
}