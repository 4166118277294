.menuWrapper {
  display: flex;
  width: 100%;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;

  .menu {
    display: flex;
    padding: 25px 0;
    width: 100%;
    max-width: 1160px;
    justify-content: right;


    a {
      color: var(--theme-color1);
      transition: color 0.3s ;
      display: flex;
      font-size: 21px;
      letter-spacing: 1px;
      font-family: "Roboto", sans-serif;
      align-items: center;
      padding: 6px 30px;
      border-right: 2px dashed #ffffff;
      white-space: nowrap;
      position: relative;
    }

    a::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: calc(100% - 60px);
      margin: 0 30px;
      height: 2px;
      background-color: var(--theme-color1);
      opacity: 0;
      transition: opacity 300ms, transform 300ms;
    }

    a:hover::after{
      opacity: 1;
      transform: translate3d(0, 0.2em, 0);
    }

    a:after {
      opacity: 1;
      transform: scale(0);
      transform-origin: center;
    }

    a:hover::after {
      transform: scale(1);
    }

    a:last-child {
      border: unset;
    }
  }

  .mobileMenuWrapper {
    display: none;
    margin-right: auto;

    .btn {
      width: 60px;
      cursor: pointer;
      flex-shrink: 0;
      z-index: 10000000;
    }

    span {
      display: block;
      width: 100%;
      height: 5px;
      background: var(--theme-color9);
      transition: all .3s;
      position: relative;
      border-radius: 10px;

      + span {
        margin-top: 11px;
        animation: top1 .5s forwards;
        animation-timing-function: ease;
      }
    }

    .active span:nth-child(1) {
      animation: top1 .5s forwards;
    }

    .notActive span:nth-child(1) {
      animation: top2 .5s forwards;
    }

    .active span:nth-child(2) {
      animation: scaled1 .5s  forwards;
    }

    .notActive span:nth-child(2) {
      animation: scaled2 .5s  forwards;
    }

    .active span:nth-child(3) {
      animation: bottom1 .5s  forwards;
    }

    .notActive span:nth-child(3) {
      animation: bottom2 .5s  forwards;
    }

    @keyframes top1 {
      0% {
        top: 0;
        transform: rotate(0);
      }

      50% {
        top: 16px;
        transform: rotate(0);
      }

      100% {
        top: 16px;
        transform: rotate(45deg);
      }
    }

    @keyframes top2 {
      0% {
        top: 16px;
        transform: rotate(45deg);
      }

      50% {
        top: 16px;
        transform: rotate(0deg);
      }

      100% {
        top: 0;
        transform: rotate(0deg);
      }
    }

    @keyframes bottom1 {
      0% {
        bottom: 0;
        transform: rotate(0);
      }

      50% {
        bottom: 16px;
        transform: rotate(0);
      }

      100% {
        bottom: 16px;
        transform: rotate(135deg);
      }
    }

    @keyframes bottom2 {
      0% {
        bottom: 16px;
        transform: rotate(135deg);
      }

      50% {
        bottom: 16px;
        transform: rotate(0);
      }

      100% {
        bottom: 0;
        transform: rotate(0);
      }
    }

    @keyframes scaled1 {
      50% {
        transform: scale(0);
      }

      100% {
        transform: scale(0);
      }
    }

    @keyframes scaled2 {
      0% {
        transform: scale(0);
      }

      50% {
        transform: scale(0);
      }

      100% {
        transform: scale(1);
      }
    }

    .menuMobile {
      display: flex;
      flex-flow: column;
      position: absolute;
      top: 0;
      left: -100%;
      background-color: var(--theme-color-main);
      padding: 70px 48px 70px 50px;
      align-items: flex-start;
      height: auto;
      z-index: 1000000;
      border-bottom: 2px solid var(--theme-color10);
      border-right: 2px solid var(--theme-color10);
      width: 470px;
      transition: left 0.6s cubic-bezier(0.77,0.2,0.05,1.0);

      a {
        font-size: 32px;
        color: #ffffff;
        text-align: left;
        padding: 25px;
        width: 100%;
        border-bottom: 5px dashed var(--theme-color14);
      }
    }

    .show {
      left: 0;
    }
  }

  .lang {
    display: flex;
    background-color: #ffffff;
    padding: 3px;
    height: 40px;
    margin-left: 45px;
    margin-right: 25px;
    user-select:none;

    .box {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 17px;
      font-family: "Roboto", sans-serif;
      cursor: pointer;
      background-color: transparent;
      padding: 8px;
      color: var(--theme-color-main);
      transition: all 0.3s;
      line-height: 1;
    }

    .boxActive {
      background-color: var(--theme-color-main);
      color: #ffffff;
    }
  }

  .theme {
    display: flex;
    border-radius: 50%;
    border: 3px solid var(--theme-color1);
    width: 40px;
    height: 40px;
    background-color: var(--theme-color2);
    cursor: pointer;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
    flex-shrink: 0;

    .halfCircle {
      background-color: var(--theme-color1);
      border-radius: 50%;
      width: 28px;
      height: 28px;
      clip-path: polygon(0 0, 50% 0, 50% 100%, 0% 100%);
      transition: 0.5s all;
    }

    .halfCircleDark {
      clip-path: polygon(0 0, 50% 0, 50% 100%, 0% 100%);
    }
  }

  .theme:hover {
    .halfCircle {
      transform: rotate(-180deg);
    }

    .halfCircleDark {
      transform: rotate(-180deg);
    }
  }
}

@media screen and (max-width: 1470px){
  .menuWrapper {

    .menu {
      max-width: unset;
      justify-content: flex-end;

      a {
        padding: 6px 1.6vw;
      }

      a::after {
        width: calc(100% - 3.2vw);
        margin: 0 1.6vw;
      }

      a:nth-child(1) {
        padding-left: unset;
      }

      a:nth-child(6) {
        padding-right: unset;
      }
    }
  }
}

@media screen and (max-width: 1300px){
  .menuWrapper {

    .lang {
      margin-left: 20px;
      margin-right: 20px;
    }

    .menu {
      max-width: 66vw;
      display: grid;
      grid-template-columns: auto auto auto;
      margin-right: auto;
      justify-content: unset;

      a {
        padding: 12px 3vw;
        width: 100%;
        font-size: 20px;
      }

      a::after {
        display: none;
      }

      a:nth-child(1) {
        padding: 12px 3vw;
        order: 0;
      }

      a:nth-child(2) {
        order: 0;
      }

      a:nth-child(3) {
        order: 1;
        border-top: 2px dashed #ffffff;
      }

      a:nth-child(4) {
        order: 1;
        border-top: 2px dashed #ffffff;
      }

      a:nth-child(5) {
        order: 0;
        border-right: unset;
      }

      a:nth-child(6) {
        order: 1;
        padding: 12px 3vw;
        border-top: 2px dashed #ffffff;
      }
    }
  }
}

@media screen and (max-width: 900px){
  .menuWrapper {

    .menu {

      a {
        padding: 12px 1.5vw;
        width: 100%;
        font-size: 19px;
      }

      a:nth-child(1) {
        padding: 12px 1.5vw;
      }

      a:nth-child(6) {

        padding: 12px 1.5vw;

      }
    }
  }
}

@media screen and (max-width: 800px){
  .menuWrapper {
    padding: 25px 0;

    .menu {
      display: none;
    }

    .mobileMenuWrapper {
      display: flex;

      .menuMobile{

        a {
          border: unset;
          display: flex;
          flex-flow: column;
          padding: 25px 0 0 0;
          font-size: 28px;
        }

        a:after {
          content: "";
          background-image: url("../../assets/images/other/line.png");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          margin-top: 25px;
          width: 100%;
          height: 4px;
        }
      }
    }
  }
}

@media screen and (max-width: 520px){
  .menuWrapper {
    padding: 25px 0;

    .mobileMenuWrapper {

      .btn {
        width: 37px;
      }

      span {
        display: block;
        width: 100%;
        height: 3px;

        + span {
          margin-top: 9px;
        }
      }

      @keyframes top1 {
        0% {
          top: 0;
          transform: rotate(0);
        }
        50% {
          top: 12px;
          transform: rotate(0);
        }
        100% {
          top: 12px;
          transform: rotate(45deg);
        }
      }

      @keyframes top2 {
        0% {
          top: 12px;
          transform: rotate(45deg);
        }
        50% {
          top: 12px;
          transform: rotate(0deg);
        }
        100% {
          top: 0;
          transform: rotate(0deg);
        }
      }

      @keyframes bottom1 {
        0% {
          bottom: 0;
          transform: rotate(0);
        }
        50% {
          bottom: 12px;
          transform: rotate(0);
        }
        100% {
          bottom: 12px;
          transform: rotate(135deg);
        }
      }

      @keyframes bottom2 {
        0% {
          bottom: 12px;
          transform: rotate(135deg);
        }
        50% {
          bottom: 12px;
          transform: rotate(0);
        }
        100% {
          bottom: 0;
          transform: rotate(0);
        }
      }

      @keyframes scaled1 {
        50% {
          transform: scale(0);
        }
        100% {
          transform: scale(0);
        }
      }

      @keyframes scaled2 {
        0% {
          transform: scale(0);
        }
        50% {
          transform: scale(0);
        }
        100% {
          transform: scale(1);
        }
      }

      .menuMobile {
        width: 100vw;
        border: unset;
        padding: 60px 30px;
        height: 100vh;
        overflow-y: scroll;

        a {
          align-items: center;
          text-align: center;
        }

        a:after {
          width: 100%;
        }
      }

      .show {
        left: 0;
      }
    }

    .lang {
      padding: 3px;
      height: 34px;

      .box {
        font-size: 15px;
        padding: 6px;
      }
    }

    .theme {
      border: 3px solid var(--theme-color1);
      width: 34px;
      height: 34px;

      .halfCircle {
        width: 22px;
        height: 22px;
      }
    }
  }
}

@media screen and (max-width: 400px){
  .menuWrapper {

    .mobileMenuWrapper {

      .menuMobile {
        padding: 60px 30px 20px;

        a {
          font-size: 24px;
          padding-top: 24px;
        }

        a:after {
          margin-top: 24px;
        }
      }
    }
  }
}