.wrapper {
  width: 100%;

  .subWrapper {
    display: flex;
    flex-flow: column;
    width: 100%;
    max-width: 940px;
    padding: 0 60px;
    margin: 2.60vw auto 0;
    position: relative;

    img{
      max-width: 100%;
      height: auto;
    }

    .content {
      display: flex;
      flex-flow: column;

      .tooltipWrapper {
        line-height: 0 !important;
        display: inline-block;

        .tooltip {
          visibility: hidden;
          max-width: 400px;
          min-width: 200px;
          background-color: var(--theme-color10);
          color: var(--theme-color12);
          text-align: center;
          position: absolute !important;
          z-index: 100;
          opacity: 0;
          transition: opacity 0.3s, visibility 0.2s !important;
          padding: 15px;
          box-shadow: 0 0 6.5px rgba(103,102,103,0.55);
          cursor: default;
          display: flex;
          align-items: center;
          justify-content: center;
          width: max-content;
          width: -moz-max-content;
          width: -webkit-max-content;
          height: auto;

          a {
            font-size: 18px !important;
            word-break: break-word;
            text-decoration: underline;
          }
        }

        a {
          font-size: 12px !important;
          color: var(--theme-color12);
          padding: 0 2px;
          font-family: "Roboto", sans-serif !important;
        }
      }

      .tooltipWrapper:hover .tooltip {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.3s, visibility 0.4s !important;
      }

      .author {
        color: var(--theme-color-main);
        transition: 0.3s all;
        font-size: 24px;
        align-self: flex-start;
        position: relative;
        margin-bottom: 55px;
      }

      .author::after {
        content: '';
        position: absolute;
        bottom: -4px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: var(--theme-color-main);
        opacity: 0;
        transition: opacity 300ms, transform 300ms;
      }

      .author:hover::after{
        opacity: 1;
        transform: translate3d(0, 0.2em, 0);
      }

      .author:after {
        opacity: 1;
        transform: scale(0);
        transform-origin: center;
      }

      .author:hover::after {
        transform: scale(1);
      }

      .title {
        font-size: 26px;
        color: var(--theme-color12);
        transition: 0.3s all;
        font-weight: 500;
        font-style: italic;
        max-width: 80%;
        margin: 0 0 5px;
      }

      .chapter {

        .chapterTitle {
          font-size: 22px;
          color: var(--theme-color12);
          transition: 0.3s all;
          font-weight: 500;
          margin: 80px 0 40px;
        }

        .chapterContent {
          display: flex;
          flex-flow: column;

          p {
            sup {
              a {
                word-break: break-word;
              }
            }
          }

          p {
            font-size: 18px !important;
            color: var(--theme-color12);
            transition: 0.3s all;
            font-family: "Roboto", sans-serif !important;
            line-height: 1.3 !important;
            position: relative;
            margin-top: unset;
            margin-bottom: 0.8em;
          }

          span {
            font-size: 18px !important;
            color: var(--theme-color12);
            transition: 0.3s all;
            font-family: "Roboto", sans-serif !important;
            position: relative;
          }

          p:last-child {
            margin-bottom: unset;
          }

          blockquote {
            margin-top: unset;
            margin-bottom: 0.8em;
          }

          a {
            color: var(--theme-color12);
          }
        }

        .subSection {
          display: flex;
          flex-flow: column;

          .subSectionTitle {
            font-size: 22px;
            color: var(--theme-color12);
            transition: 0.3s all;
            font-weight: 500;
            margin: 80px 0 40px;
          }

          .subSectionContent {
            display: flex;
            flex-flow: column;

            p {
              font-size: 18px;
              color: var(--theme-color12);
              transition: 0.3s all;
              font-family: "Roboto", sans-serif !important;
              line-height: 1.3 !important;
              position: relative;
              margin-top: unset;
              margin-bottom: 0.8em;

              a {
                word-break: break-word;
              }
            }

            span {
              font-size: 18px;
              color: var(--theme-color12);
              transition: 0.3s all;
              font-family: "Roboto", sans-serif !important;
              position: relative;
            }

            p:first-child {
              margin-top: unset;
            }

            p:last-child {
              margin-bottom: unset;
            }

            blockquote {
              margin-top: unset;
              margin-bottom: 0.8em;
            }

            a {
              color: var(--theme-color12);
            }
          }
        }
      }

      .bibliography {
        border-top: 2px dashed var(--theme-color9);
        margin-top: 40px;
        padding-top: 40px;
        flex-flow: column;

        p {
          position: relative;
          font-size: 16px !important;
          line-height: 1.2 !important;
          font-family: "Roboto", sans-serif !important;
          margin: 3px 0 3px 50px;
        }

        span {
          font-size: 16px !important;
          line-height: 1.2 !important;
          font-family: "Roboto", sans-serif !important;

          span {

            a {
              display: inline !important;
              left: 0 !important;
              position: relative !important;
              word-break: break-word;
              text-decoration: underline;
            }
          }
        }

        a {
          display: flex;
          justify-content: flex-start;
          position: absolute;
          left: -50px;
          font-size: 16px !important;
          line-height: 1.2 !important;
          color: var(--theme-color12) !important;
          font-family: "Roboto", sans-serif !important;
        }
      }

      .phoneContentsWrapper {
        display: none;
        flex-flow: column;
        width: 100%;
        border-top: 6px solid var(--theme-color-main);
        border-bottom: 6px solid var(--theme-color-main);
      }

      .stickyWrapper {

      }
    }

    .mobileContents {
    }

    .contentsOpen {
      display: none;
      margin-right: auto;
      width: 60px;
      height: 60px;
      position: absolute;
      top: -2px;
      left: -60px;
      align-items: center;
      justify-content: center;
      background-color: var(--theme-color10);
      border: 2px solid var(--theme-color13);
      color: white;

      .btn {
        width: 37px;
        cursor: pointer;
        flex-shrink: 0;
        z-index: 10000;
      }

      span {
        display: block;
        width: 100%;
        height: 3px;
        background: var(--theme-color13);
        transition: all .3s;
        position: relative;
        border-radius: 10px;

        + span {
          margin-top: 9px;
          animation: top1 .5s forwards;
          animation-timing-function: ease;
        }
      }

      .active span:nth-child(1) {
        animation: top1 .5s forwards;
      }

      .notActive span:nth-child(1) {
        animation: top2 .5s forwards;
      }

      .active span:nth-child(2) {
        animation: scaled1 .5s  forwards;
      }

      .notActive span:nth-child(2) {
        animation: scaled2 .5s  forwards;
      }

      .active span:nth-child(3) {
        animation: bottom1 .5s  forwards;
      }

      .notActive span:nth-child(3) {
        animation: bottom2 .5s  forwards;
      }

      @keyframes top1 {
        0% {
          top: 0;
          transform: rotate(0);
        }

        50% {
          top: 12px;
          transform: rotate(0);
        }

        100% {
          top: 12px;
          transform: rotate(45deg);
        }
      }

      @keyframes top2 {
        0% {
          top: 12px;
          transform: rotate(45deg);
        }

        50% {
          top: 12px;
          transform: rotate(0deg);
        }

        100% {
          top: 0;
          transform: rotate(0deg);
        }
      }

      @keyframes bottom1 {
        0% {
          bottom: 0;
          transform: rotate(0);
        }

        50% {
          bottom: 12px;
          transform: rotate(0);
        }

        100% {
          bottom: 12px;
          transform: rotate(135deg);
        }
      }

      @keyframes bottom2 {
        0% {
          bottom: 12px;
          transform: rotate(135deg);
        }

        50% {
          bottom: 12px;
          transform: rotate(0);
        }

        100% {
          bottom: 0;
          transform: rotate(0);
        }
      }

      @keyframes scaled1 {
        50% {
          transform: scale(0);
        }

        100% {
          transform: scale(0);
        }
      }

      @keyframes scaled2 {
        0% {
          transform: scale(0);
        }

        50% {
          transform: scale(0);
        }

        100% {
          transform: scale(1);
        }
      }
    }

    .spisWrapper {
      display: none;
      transition: height 0.4s ease-in-out;
      width: calc(100% - 18px);
      margin: 0 auto;
    }

    .spisWrapperOpen {

    }

    .pointWrapper {
      display: flex;
      flex-flow: column;
      align-items: flex-start;

      .pointParagraph {
        margin: 0;
        text-align: left;
        position: relative;
      }

      .pointContent {
        display: flex;
        cursor: pointer;
        margin: 6px 0;
        align-items: center;
        position: relative;

        .pointText {
          font-size: 12px;
          color: var(--theme-color12);
          font-weight: 500;
          transition: 0.3s all;
          position: relative;
        }

        .pointText:after {
          content: '';
          position: absolute;
          bottom: -1px;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: var(--theme-color12);
          opacity: 0;
          transition: opacity 300ms, transform 300ms;
          transform: scale(0);
        }

        .pointArrow {
          margin-left: 5px;
          /*background-color: var(--theme-color-main);
          clip-path: polygon(0 0%, 0% 100%, 100% 50%);
          width: 9px;
          height: 9px;*/
          transition: 0.4s all;
          color: var(--theme-color-main);
          font-size: 18px;
          line-height: 1.1;
          position: absolute;
        }

        .pointArrowOpen {
          transform: rotate(90deg);
        }
      }

      .pointContentActive {

        .pointText {
          color: var(--theme-color-main);
        }
      }

      .pointContentActiveDark {

        .pointText:after {
          opacity: 1;
          transform-origin: center;
          transform: scale(1);
        }
      }

      .subPoints {
        display: flex;
        flex-flow: column;
        width: calc(100% - 5px);
        margin-left: 5px;
        align-items: flex-start;
        transition: height 0.5s ease-out;
        height: 0;
        overflow: hidden;

        .subPoint {
          cursor: pointer;
          font-size: 12px;
          color: var(--theme-color5);
          transition: 0.3s all;
          margin: 2px 0;
          position: relative;
        }

        .subPoint:after {
          content: '';
          position: absolute;
          bottom: -1px;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: var(--theme-color12);
          opacity: 0;
          transition: opacity 300ms, transform 300ms;
          transform: scale(0);
        }

        .subPointActive {
          color: var(--theme-color-main);
        }

        .subPointActiveDark:after {
          opacity: 1;
          transform-origin: center;
          transform: scale(1);
        }
      }
    }

    .contentsWrapper {
      display: flex;
      flex-flow: column;
      position: absolute;
      top: 0;
      right: -220px;
      height: 100%;
      width: 280px;
      user-select: none;
      transition: top 0s ease;

      .contents {
        position: sticky;
        display: flex;
        flex-flow: column;
        top: 20px;
        margin: 0 35px;
        max-height: calc(100vh - 40px);
        overflow-y: auto;

        .title {
          font-size: 14px;
          font-style: italic;
          color: var(--theme-color12);
          font-weight: 500;
          margin-bottom: 8px;
          transition: 0.3s all;
        }
      }
    }

    .contentsWrapperActive {
      width: 0;
    }
  }
}

@media screen and (max-width: 1400px) {
  .wrapper {

    .subWrapper {
      padding: 0 40px;
      max-width: 900px;

      .contentsWrapper {
        right: -240px;
      }
    }
  }
}

@media screen and (max-width: 1381px) {
  .wrapper {

    .subWrapper {
      margin-left: calc(100vw - 1145px);
    }
  }
}

@media screen and (min-width: 1146px) {
  .wrapper {

    .subWrapper {

      .contentsWrapper {

        .contents::-webkit-scrollbar-thumb {
          background: var(--theme-color-main);
          width: 8px;
          margin: 10px 0;
        }

        .contents::-webkit-scrollbar-corner {
          background: #ebecec;
        }

        .contents::-webkit-scrollbar {
          background: #cfcfcf;
          width: 10px;
          border: 3px solid white;
        }
      }
    }
  }
}

@media screen and (max-width: 1145px) {
  .wrapper {

    .subWrapper {
      margin-left: auto;
      padding: unset;
      max-width: unset;

      .content {
        padding: 0 40px;
        max-width: 900px;
        margin-left: auto;
        margin-right: auto;
      }

      .mobileContents {
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        z-index: 10;
      }

      .contentsWrapper {
        background-color: white;
        position: sticky;
        border: 2px solid transparent;
        border-right: unset;
        transition: width 0.6s ease-in-out, border 0.4s ease-in-out;
        top: 0;
        width: 0;
        padding-bottom: 20px;

        .contents {
          margin-left: unset;
          margin-right: unset;
          margin-top: -2px;
          opacity: 0;
          transition: 0.4s ease-in-out;
          overflow-x: hidden;

          .title {
            width: 205px;
            flex-shrink: 0;
            display: flex;
          }

          .pointWrapper {
            width: 205px;
            flex-shrink: 0;
            display: flex;
          }
        }

        .contentsOpen {
          display: flex;
          margin-left: -60px;
          margin-top: -2px;
          position: sticky;
        }
      }

      .contentsWrapperActive {
        width: 280px;
        border: 2px solid var(--theme-color13);
        border-right: unset;


        .contents {
          margin-left: 35px;
          padding-right: 35px;
          opacity: 1;
          transition: 1.5s;
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .wrapper {

    .subWrapper {

      .content {

        .author {
          text-align: center;
        }

        .title {
          margin-top: 30px;
          max-width: unset;
          text-align: center;
        }
      }
    }
  }
}

@media screen and (max-width: 520px) {
  .wrapper {

    .subWrapper {
      padding: 0 30px;

      .content {
        padding: unset;
        margin: unset;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .wrapper {

    .subWrapper {

      .content {

        .tooltipWrapper {
          line-height: 0;

          .tooltip {
            max-width: calc(100vw - 60px);

            a {
              font-size: 16px !important;
              word-break: break-word;
            }
          }
        }

        .stickyWrapper {
          height: 72px;
          position: sticky;
          z-index: 100000;
          top: 0;
        }

        .phoneContentsWrapper {
          display: flex;
          position: sticky;
          top: 0;
          background-color: var(--theme-color10);
          z-index: 1000;
        }

        .author {
          font-size: 20px;
          margin-bottom: 25px;
        }

        .title {
          margin: 0 0 25px;
          font-size: 20px;
        }

        .chapter {

          .chapterTitle {
            font-size: 18px;
            margin: 40px 0 20px;
          }

          .chapterContent {

            p {
              font-size: 15px !important;
            }

            span {
              font-size: 15px !important;
            }

            .tooltipWrapper {
              line-height: 0;

              .tooltip {
                max-width: calc(100vw - 60px);

                a {
                  font-size: 15px !important;
                }
              }

              a {
                font-size: 10px !important;
              }
            }
          }

          .subSection {

            .subSectionTitle {
              font-size: 18px;
              margin: 40px 0 20px;
            }

            .subSectionContent {

              p {
                font-size: 15px !important;
              }

              span {
                font-size: 15px !important;
                display: none;
              }

              .tooltipWrapper {
                line-height: 0;

                a {
                  font-size: 10px !important;
                }
              }
            }
          }
        }

        .bibliography {
          margin-top: 30px;
          padding-top: 30px;

          p{
            margin-left: 30px;
            font-size: 14px !important;
          }

          span {
            font-size: 14px !important;
            line-height: 1.2 !important;
          }

          a {
            left: -30px;
            font-size: 14px !important;
          }
        }
      }

      .spisWrapper {
        flex-flow: column;
        max-height: calc(100vh - 72px);
        padding: 0 5px;
      }

      .spisWrapper::-webkit-scrollbar-thumb {
        background: var(--theme-color-main);
        width: 8px;
        margin: 10px 0;
      }

      .spisWrapper::-webkit-scrollbar-corner {
        background: #ebecec;
      }

      .spisWrapper::-webkit-scrollbar {
        background: #cfcfcf;
        width: 10px;
        border: 3px solid white;
      }

      .spisWrapperOpen {
        display: flex;
        overflow-y: hidden;
      }

      .pointWrapper {
        align-items: center;

        .pointParagraph {
          text-align: center;
        }

        .pointContent {
          margin: 10px 0;

          .pointText{
            font-size: 16px;
            font-weight: unset;
          }
        }

        .subPoints {
          align-items: center;

          .subPoint {
            font-size: 14px;
          }
        }
      }

      .pointWrapper:last-child {
        margin-bottom: 20px;
      }

      .contentsOpen {
        display: flex;
        position: relative;
        left: unset;
        top: unset;
        border: unset;
        margin: 0 auto;

        .spis {
          color: var(--theme-color-main);
          display: flex;
          flex-shrink: 0;
          font-size: 18px;
          margin-right: 10px;
        }

        .btn {
          width: 35px;
          cursor: pointer;
          flex-shrink: 0;
          z-index: 10000;
        }

        span {
          display: block;
          width: 100%;
          height: 4px;
          background: var(--theme-color-main);
          transition: all .3s;
          position: relative;
          border-radius: 10px;

          + span {
            margin-top: 5px;
          }
        }

        @keyframes top1 {
          0% {
            top: 0;
            transform: rotate(0);
          }

          50% {
            top: 9px;
            transform: rotate(0);
          }

          100% {
            top: 9px;
            transform: rotate(45deg);
          }
        }

        @keyframes top2 {
          0% {
            top: 9px;
            transform: rotate(45deg);
          }

          50% {
            top: 9px;
            transform: rotate(0deg);
          }

          100% {
            top: 0;
            transform: rotate(0deg);
          }
        }

        @keyframes bottom1 {
          0% {
            bottom: 0;
            transform: rotate(0);
          }

          50% {
            bottom: 9px;
            transform: rotate(0);
          }

          100% {
            bottom: 9px;
            transform: rotate(135deg);
          }
        }

        @keyframes bottom2 {
          0% {
            bottom: 9px;
            transform: rotate(135deg);
          }

          50% {
            bottom: 9px;
            transform: rotate(0);
          }

          100% {
            bottom: 0;
            transform: rotate(0);
          }
        }

        @keyframes scaled1 {
          50% {
            transform: scale(0);
          }

          100% {
            transform: scale(0);
          }
        }

        @keyframes scaled2 {
          0% {
            transform: scale(0);
          }

          50% {
            transform: scale(0);
          }

          100% {
            transform: scale(1);
          }
        }
      }

      .mobileContents {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .wrapper {

    .subWrapper {
      padding: 0 20px;

      .content {

        .tooltipWrapper {


          .tooltip {
            max-width: calc(100vw - 40px);
            display: none;
          }
        }
      }
    }
  }
}