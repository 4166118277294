.wrapper {
  display: flex;
  width: 100%;
  max-width: 1210px;
  background-color: var(--theme-color9);
  /*transition: background-color 0.3s;*/
  padding: 10px;
  justify-content: space-between;
  margin-bottom: 38px;
  transition: 0.3s all;

  .searchOptions {
    box-shadow: var(--theme-box-shadow);
    background-color: var(--theme-color-main);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 11px 14px;
    transition: box-shadow 0.3s, background-color 0.3s,border 0.3s;
    position: relative;
    border: 1px solid transparent;
    user-select: none;
    min-width: 196px;

    .more {
      clip-path: polygon(50% 100%, 0 0, 100% 0);
      background-color: var(--theme-color10);
      width: 16px;
      height: 9px;
      transition: 0.5s all;
      flex-shrink: 0;
    }

    .moreRotate {
      transform: rotate(180deg);
    }

    .text {
      font-size: 15px;
      color: var(--theme-color10);
      transition: 0.3s all;
      margin-left: auto;
      margin-right: auto;
      font-weight: 500;
      white-space: nowrap;
      margin-right: auto;
    }

    .optionsMenu {
      display: none;
      width: calc(100% + 2px);
      background-color: var(--theme-color-main);
      overflow: hidden;
      position: absolute;
      top: 100%;
      left: -1px;
      transition: 0.3s height ease-in-out, 0.3s background-color;
      flex-flow: column;

      .optionText {
        font-size: 15px;
        color: var(--theme-color10);
        font-weight: 500;
        text-align: left;
        padding: 11px 14px;
        width: 100%;
        transition: 0.3s all;
      }

      .optionTextHide {
        display: none;
      }

      .optionText:before {
        content: "";
        margin-left: 29px;
      }

      .optionTextHide {
        content: "";
        margin-left: 29px;
      }
    }

    .optionsMenuOpen {
      display: flex;
    }
  }

  .searchOptionsOpened {
    border-top:  1px solid var(--theme-color10);
    border-right: 1px solid var(--theme-color10);
    border-left: 1px solid var(--theme-color10);
  }

  .subWrapper {
    display: flex;
    width: 100%;

    .input {
      background-color: var(--theme-color2);
      font-size: 18px;
      border: 1px solid var(--theme-color11);
      box-shadow: var(--theme-box-shadow);
      color: var(--theme-color1);
      padding: 0 10px;
      width: 100%;
      margin: 0 12px;
      transition: box-shadow 0.3s ,color 0.3s, background-color 0.3s, border 0.3s;
    }

    .searchButton {
      box-shadow: var(--theme-box-shadow);
      background-color: var(--theme-color-main);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 11px 14px;
      transition: 0.3s all;
      background-image: url("../../assets/images/other/search.png");
      background-position: center;
      background-size: 28px 28px;
      background-repeat: no-repeat;
      width: 47px;
      height: 44px;
      cursor: pointer;
      flex-shrink: 0;
    }
  }
}

@media screen and (max-width: 800px) {
  .wrapper {
    margin: unset;
    padding: 20px 40px;
  }
}

@media screen and (max-width: 650px) {
  .wrapper {
    flex-flow: column;


    .searchOptions {
      order: 1;
      justify-content: center;
      max-width: 50%;
      width: 100%;
      margin: 0 auto;

      .more {
        order: 1;
      }

      .text {
        order: 0;
        margin-left: unset;
        margin-right: 10px;
      }

      .optionsMenu {

        .optionText {
          margin-left: unset;
          text-align: center;
        }

        .optionText:before {
          content: "";
          margin-left: unset;
        }
      }
    }

    .subWrapper {
      order: 0;
      margin-bottom: 10px;

      .input {
        margin-left: unset;
      }
    }
  }
}

@media screen and (max-width: 520px) {
  .wrapper {
    padding: 20px 30px;
  }
}

@media screen and (max-width: 400px) {
  .wrapper {
    padding: 20px 20px;

    .searchOptions {
      max-width: unset;
    }
  }
}