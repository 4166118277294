.playerWrapper {
  position: relative;
  padding-top: 56.25% ;/* Player ratio: 100 / (1280 / 720) */

  .reactPlayer {
    position: absolute;
    top: 0;
    left: 0;
  }

  .background {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0, 0.5);
    align-items: center;
    justify-content: center;

    .button {
      width: 111px;
      height: 111px;
      background-image: url("../../assets/images/player/playButton.png");
      background-size: 100% auto;
      background-position: center;
      background-repeat: no-repeat;
    }
  }


  iframe {

    .ytp-expand {
      display: none !important;
    }

    .ytp-title {
      display: none !important;
    }
  }

}