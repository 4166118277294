.wrapper {
  width: 100%;
  margin: 0 auto;

  .subWrapper {
    display: flex;
    width: 100%;
    max-width: 940px;
    padding: 0 60px;
    margin: 0 auto;
    position: relative;
    flex-flow: column;

    .title {
      font-size: 24px;
      color: var(--theme-color12);
      width: 100%;
      border-bottom: 12px solid var(--theme-color-main);
      padding-bottom: 25px;
      transition: 0.3s all;
      display: flex;
      margin-top: 30px;
    }

    .container {
      display: flex;
      padding: 30px 0;
      border-bottom: 3px dotted var(--theme-color5);
      width: 100%;

      .image {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 258px;
        height: 233px;
        flex-shrink: 0;
        margin: auto 50px auto 0;
        border: 2px solid var(--theme-color-main);
      }

      .right {
        display: flex;
        flex-flow: column;
        align-items: flex-start;

        .date {
          font-size: 16px;
          color: var(--theme-color5);
          margin-top: 10px;
        }

        .multimediaTitle {
          font-size: 20px;
          color: var(--theme-color12);
          margin-top: 20px;
          margin-bottom: 4px;
        }

        .place {
          font-size: 16px;
          color: var(--theme-color5);
          margin: auto 0;
        }

        .more {
          font-size: 16px;
          color: var(--theme-color-main);
          margin-bottom: 10px;
          margin-top: 4px;
          position: relative;

          &::after {
            content: '';
            position: absolute;
            bottom: -3px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: var(--theme-color-main);
            opacity: 0;
            transition: opacity 300ms, transform 300ms;
          }

          &:hover::after{
            opacity: 1;
            transform: translate3d(0, 0.2em, 0);
          }

          &:after {
            opacity: 1;
            transform: scale(0);
            transform-origin: center;
          }

          &:hover::after {
            transform: scale(1);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .wrapper {

    .subWrapper {
      padding: 0 40px;
    }
  }
}

@media screen and (max-width: 800px) {
  .wrapper {

    .subWrapper {

      .title {
        font-size: 22px;
        border-bottom: 9px solid var(--theme-color-main);
        padding-bottom: 20px;
        margin-top: 20px;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .wrapper {

    .subWrapper {

      .container {

        .image {
          width: 219px;
          height: 198px;
          margin: auto 30px auto 0;
        }

        .right {

          .date {
            font-size: 15px;
          }

          .multimediaTitle {
            font-size: 18px;
            margin-top: 15px;
          }

          .place {
            font-size: 15px;
            margin: auto 0;
          }

          .more {
            font-size: 15px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .wrapper {

    .subWrapper {

      .title {
        justify-content: center;
      }

      .container {
        flex-flow: column;

        .image {
          width: 100%;
          height: 45.45vw;
          margin: auto;
        }

        .right {
          align-items: center;

          .date {
            font-size: 18px;
            margin-top: 30px;
          }

          .multimediaTitle {
            font-size: 20px;
            margin-top: 15px;
            text-align: center;
          }

          .place {
            font-size: 18px;
            margin: 15px 0;
          }

          .more {
            font-size: 18px;
            margin-bottom: unset;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .wrapper {

    .subWrapper {

      .title {
        border-bottom: 6px solid var(--theme-color-main);
      }
    }
  }
}

@media screen and (max-width: 520px) {
  .wrapper {

    .subWrapper {
      padding: 0 30px;
    }
  }
}

@media screen and (max-width: 450px) {
  .wrapper {

    .subWrapper {

      .title {
        font-size: 20px;
        padding-bottom: 15px;
        margin-top: 10px;
      }

      .container {
        padding: 20px 0;

        .right {

          .date {
            font-size: 16px;
            margin-top: 20px;
          }

          .multimediaTitle {
            font-size: 18px;
          }

          .place {
            font-size: 16px;
            margin: 10px;
          }

          .more {
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .wrapper {

    .subWrapper {
      padding: 0 20px;
    }
  }
}