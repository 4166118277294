.wrapper {
  width: 100%;
  margin: 0 auto;


  .subWrapper {
    display: flex;
    flex-flow: column;
    width: 100%;
    max-width: 940px;
    padding: 0 60px;
    margin: 5px auto 0;
    position: relative;

    .polygon {
      display: flex;
      position: absolute;
      left: -213px;
      top: 45px;
      height: 100%;

      .imageWrapper {
        display: flex;
        transition: background 0.3s;
        align-items: center;
        justify-content: center;
        width: 163px;
        height: 189px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        clip-path: polygon(50% 0, 100% 26%, 100% 74%, 50% 100%, 0 74%, 0 26%);
        background-color: var(--theme-color-main);
        margin: 0 50px 50px;
        flex-shrink: 0;
        position: sticky;
        top: 100px;

        img {
          z-index: 1000;
          max-width: 95px;
        }

        .uni {
          max-width: 108px;
        }

        .creator_1 {
          max-width: 109px;
          margin-left: 17px;
        }

        .creator_2 {
          max-width: 100px;
          margin-right: 5px;
        }

        .creator_3 {
          max-width: 95px;
        }

        .creator_4 {
          max-width: 125px;
        }

      }

      .imageWrapper:before {
        content: '';
        position: absolute;
        top: 5px;
        left: 5px;
        clip-path: polygon(50% 0, 100% 26%, 100% 74%, 50% 100%, 0 74%, 0 26%);
        background-color: white;
        width: 153px;
        height: 179px;
      }

      .noMargin {
        margin: unset;
      }
    }

    .polygonList {
      top: 100px;

      .imageWrapper {
        margin-bottom: 100px;
      }
    }

    .title {
      font-size: 24px;
      color: var(--theme-color12);
      width: 100%;
      border-bottom: 12px solid var(--theme-color-main);
      padding-bottom: 25px;
      transition: 0.3s all;
      display: flex;
      margin-top: 30px;
    }

    .noMarginTitle {
    }

    .institutionWrapper {
      display: flex;
      flex-flow: column;
      align-items: flex-start;

      .topWrapper {
        display: flex;
        width: 100%;

        .right {
          display: flex;
          flex-flow: column;
          margin: auto 0;

          .name {
            font-size: 24px;
            color: var(--theme-color12);
            font-weight: 500;
            margin: 35px 0 32px;
          }

          .link {
            font-size: 20px;
            color: var(--theme-color12);
            text-decoration: underline;
            display: flex;
            flex-shrink: 0;
            align-items: center;
            word-break: break-word;

            .arrow {
              background-color: var(--theme-color12);
              clip-path: polygon(100% 50%, 0 0, 0 100%);
              width: 14px;
              height: 12px;
              margin-right: 10px;
              margin-top: 3px;
            }
          }
        }
      }

      .image {
        width: 100%;
        margin: 20px 0;
      }

      .text {

        p {
          font-size: 18px !important;
          line-height: 21px !important;
          color: var(--theme-color12);
        }

        span {
          font-size: 18px !important;
          line-height: 21px !important;
          color: var(--theme-color12);
        }

        p:last-child {
          margin-bottom: unset;
        }
      }

      .back {
        font-size: 20px;
        color: var(--theme-color-main);
        display: flex;
        flex-shrink: 0;
        align-items: center;
        margin-top: 30px;
        position: relative;

        .arrow {
          background-color: var(--theme-color-main);
          clip-path: polygon(0 50%, 100% 100%, 100% 0);
          width: 14px;
          height: 12px;
          margin-right: 10px;
        }

        &::after {
          content: '';
          position: absolute;
          bottom: -3px;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: var(--theme-color-main);
          opacity: 0;
          transition: opacity 300ms, transform 300ms;
        }

        &:hover::after{
          opacity: 1;
          transform: translate3d(0, 0.2em, 0);
        }

        &:after {
          opacity: 1;
          transform: scale(0);
          transform-origin: center;
        }

        &:hover::after {
          transform: scale(1);
        }
      }
    }

    .list {
      display: flex;
      flex-flow: column;
      align-items: center;

      .center {
      }

      .container {
        display: flex;
        padding: 30px 0;
        border-bottom: 3px dotted var(--theme-color5);
        width: 100%;

        .image {
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          width: 258px;
          height: 233px;
          flex-shrink: 0;
          margin: auto 50px auto 0;
          border: 2px solid var(--theme-color-main);
          transition: border 0.3s ;
        }

        .right {
          display: flex;
          flex-flow: column;
          align-items: flex-start;

          .name {
            font-size: 20px;
            color: var(--theme-color-main);
            font-weight: 500;
          }

          .uni {
            font-size: 16px;
            color: var(--theme-color5);
            margin-top: 5px;
          }

          .mail {
            font-size: 16px;
            color: var(--theme-color5);
          }

          .redact {
            font-size: 18px;
            color: var(--theme-color12);
            font-weight: 500;
            margin-top: auto;
          }

          .tractateWrapper {
            display: flex;
            flex-flow: column;
            align-items: flex-start;
            margin-bottom: auto;
          }

          .tractate {
            font-size: 16px;
            color: var(--theme-color12);
            margin-top: 5px;
            margin-bottom: auto;
          }

          a.tractate {

            span {
              background: linear-gradient(to right, rgb(255, 255, 255), rgb(255, 255, 255)), linear-gradient(to right, var(--theme-color12), var(--theme-color12));
              background-size: 100% 1px, 0 1px;
              background-position: 100% 100%, 0 100%;
              background-repeat: no-repeat;
              transition: background-size 400ms;
              padding-bottom: 1px;
              line-height: 1.3;
            }

            &:hover {

              span {
                background-size: 0 1px, 100% 1px;
              }
            }
          }

          .translator {
            font-size: 16px;
            color: var(--theme-color12);
            margin-bottom: auto;
            margin-top: 2px;
          }

          .more {
            font-size: 16px;
            color: var(--theme-color-main);
            margin-bottom: 5px;
            position: relative;

            &::after {
              content: '';
              position: absolute;
              bottom: -3px;
              left: 0;
              width: 100%;
              height: 2px;
              background-color: var(--theme-color-main);
              opacity: 0;
              transition: opacity 300ms, transform 300ms;
            }

            &:hover::after{
              opacity: 1;
              transform: translate3d(0, 0.2em, 0);
            }

            &:after {
              opacity: 1;
              transform: scale(0);
              transform-origin: center;
            }

            &:hover::after {
              transform: scale(1);
            }
          }
        }
      }
    }

    .back {
      font-size: 20px;
      color: var(--theme-color-main);
      display: flex;
      flex-shrink: 0;
      align-items: center;
      margin-top: 30px;
      position: relative;
      align-self: flex-start;

      .arrow {
        background-color: var(--theme-color-main);
        clip-path: polygon(0 50%, 100% 100%, 100% 0);
        width: 14px;
        height: 12px;
        margin-right: 10px;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: -3px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: var(--theme-color-main);
        opacity: 0;
        transition: opacity 300ms, transform 300ms;
      }

      &:hover::after{
        opacity: 1;
        transform: translate3d(0, 0.2em, 0);
      }

      &:after {
        opacity: 1;
        transform: scale(0);
        transform-origin: center;
      }

      &:hover::after {
        transform: scale(1);
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .wrapper {

    .subWrapper {
      padding: 0 40px;
    }
  }
}

@media screen and (max-width: 1350px) {
  .wrapper {

    .subWrapper {

      .polygon {
        position: relative;
        top: 0;
        left: 0;

        .imageWrapper {
          margin-bottom: unset;
        }
      }

      .title {
        margin-top: 25px;
      }

      .noMarginTitle {
        margin-top: 20px;
      }

      .institutionWrapper {

        .topWrapper {
          margin: 30px 0;

          .right {

            .name {
              margin: 0 0 20px;
            }
          }
        }

        .image {
          margin-top: 0;
        }
      }

      .list {

        .center {
          justify-content: center;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .wrapper {

    .subWrapper {

      .polygon {

        .imageWrapper {
          height: 23vw;
          width: 20.37vw;
          margin: auto 30px;

          img {
            max-width: 11.875vw;
          }

          .uni {
            max-width: 13.5vw;
          }

          .creator_1 {
            max-width: 13.625vw;
            margin-left: 2.125vw;
          }

          .creator_2 {
            max-width: 12.5vw;
            margin-right: 0.625vw;
          }

          .creator_3 {
            max-width: 11.875vw;
          }

          .creator_4 {
            max-width: 15.625vw;
          }
        }

        .imageWrapper:before {
          height: 21.7vw;
          width: 19.13vw;
          top: 0.62vw;
          left: 0.62vw ;
        }

        .noMargin {
          margin: unset;
        }
      }

      .title {
        font-size: 22px;
        border-bottom: 9px solid var(--theme-color-main);
        padding-bottom: 15px;
        margin-top: 25px;
      }

      .noMarginTitle {
        margin-top: 10px;
      }

      .institutionWrapper {

        .topWrapper {
          margin: 20px 0;

          .right {

            .name {
              margin: 0 0 15px;
              font-size: 22px;
            }

            .link {
              font-size: 18px;

              .arrow {
                height: 10px;
                width: 12px;
              }
            }
          }
        }

        .text {

          p {
            font-size: 14px !important;
            line-height: 18px !important;
          }

          span {
            font-size: 14px !important;
            line-height: 18px !important;
          }
        }

        .back {
          font-size: 18px;

          .arrow {
            width: 12px;
            height: 10px;
          }
        }
      }

      .back {
        font-size: 18px;

        .arrow {
          width: 12px;
          height: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .wrapper {

    .subWrapper {

      .list {
        display: flex;
        flex-flow: column;
        align-items: center;

        .container {

          .image {
            width: 219px;
            height: 198px;
            margin: auto 30px auto 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 620px) {
  .wrapper {

    .subWrapper {

      .polygon {

        .imageWrapper {
          margin: auto 20px;
        }

        .noMargin {
          margin: unset;
        }
      }

      .title {
        font-size: 20px;
        border-bottom: 6px solid var(--theme-color-main);
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .wrapper {

    .subWrapper {

      .list {

        .center {
        }

        .container {
          display: flex;
          padding: 30px 0;
          border-bottom: 3px dotted var(--theme-color5);
          width: 100%;
          align-items: center;
          flex-flow: column;

          .image {
            width: 169px;
            height: 153px;
            margin: 0 auto;
          }

          .right {
            align-items: center;
            margin-top: 30px;

            .name {
              font-size: 20px;
              text-align: center;
            }

            .uni {
              font-size: 16px;
              margin-top: 20px;
              text-align: center;
            }

            .mail {
              font-size: 16px;
              text-align: center;
            }

            .redact {
              font-size: 18px;
              margin-top: 30px;
              text-align: center;
            }

            .tractate {
              font-size: 16px;
              margin-top: 5px;
              text-align: center;
              margin-bottom: 30px;
            }

            .translator {
              font-size: 16px;
              margin-bottom: 30px;
              margin-top: 2px;
              text-align: center;
            }

            .more {
              font-size: 18px;
              margin-bottom: unset;
              text-align: center;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 520px) {
  .wrapper {

    .subWrapper {
      padding: 0 30px;

      .polygon {

        .imageWrapper {
          height: 112px;
          width: 100px;
          margin: auto 20px auto 10px;

          img {
            max-width: 58px;
          }

          .uni {
            max-width: 66px;
          }

          .creator_1 {
            max-width: 67px;
            margin-left: 10px;
          }

          .creator_2 {
            max-width: 62px;
            margin-right: 3px;
          }

          .creator_3 {
            max-width: 62px;
          }

          .creator_4 {
            max-width: 78px;
          }
        }

        .imageWrapper:before {
          height: 106px;
          width: 94px;
          top: 3px;
          left: 3px;
        }

        .noMargin {
          margin: unset;
        }
      }

      .institutionWrapper {

        .topWrapper {
          margin: 15px 0;

          .right {

            .name {
              font-size: 18px;
            }

            .link {
              font-size: 14px;

              .arrow {
                margin-top: unset;
              }
            }
          }
        }

        .back {
          font-size: 16px;
        }
      }

      .back {
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .wrapper {

    .subWrapper {
      padding: 0 20px;

      .polygon {

        .imageWrapper {
          height: 100px;
          width: 90px;
          margin: auto 15px auto 0;

          img {
            max-width: 53px;
          }

          .uni {
            max-width: 61px;
          }

          .creator_1 {
            max-width: 62px;
            margin-left: 8px;
          }

          .creator_2 {
            max-width: 57px;
            margin-right: 2px;
          }

          .creator_3 {
            max-width: 57px;
          }

          .creator_4 {
            max-width: 73px;
          }
        }

        .imageWrapper:before {
          height: 94px;
          width: 84px;
          top: 3px;
          left: 3px;
        }

        .noMargin {
          margin: unset;
        }
      }
    }
  }
}

@media screen and (max-width: 355px) {
  .wrapper {

    .subWrapper {

      .institutionWrapper {

        .topWrapper {

          .right {

            .name {
              font-size: 16px;
            }

            .link {
              font-size: 13px;
            }
          }
        }

        .back {
          font-size: 14px;
        }
      }

      .back {
        font-size: 14px;
      }
    }
  }
}