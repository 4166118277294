.scrollTopButton {
  position: fixed;
  bottom: -55px;
  left: 30px;
  background-image: url(../../../assets/images/other/arrowDownColor.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 30px auto;
  background-color: #ffffff;
  width: 50px;
  height: 50px;
  padding: 10px;
  border-radius: 5px;
  border: 2px var(--theme-color-main) solid;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s all ease-in-out;
  z-index: 5;
  transform: rotate(180deg) perspective(100px) translateZ(0);
}

.scrollTopButtonBlack {
  background-image: url(../../../assets/images/other/arrowDownBlack.svg);
}

.scrollTopButton:hover {
  transform: rotate(180deg) perspective(100px) translateZ(10px);
  opacity: 1;
}

.show {
  bottom: 50px;
}

@media screen and (max-width: 1000px) {
  .scrollTopButton {
    width: 40px;
    height: 40px;
    left: 15px;
    background-size: 22px auto;
    border: 1px var(--theme-color-main) solid;
  }
}