.wrapper {
  width: 100%;

  .subWrapper {
    display: flex;
    width: 100%;
    margin: 4.16vw auto 0;
    max-width: 940px;
    padding: 0 60px;
    flex-flow: column;

    .rightTitle {
      font-size: 24px;
      letter-spacing: 1px;
      color: var(--theme-color12);
      font-weight: 500;
      width: 100%;
      text-align: right;
      margin-bottom: 15px;
      flex-wrap: wrap;
      flex-flow: row;
      display: flex;
      justify-content: flex-end;
      transition: 0.3s all;

      .title {
        display: flex;
        white-space: nowrap;
        margin-right: 5px;
      }

      .searchText {
        color: var(--theme-color-main);
        font-weight: 400;
        text-align: left;
        transition: 0.3s all;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .bar{
      background-color: var(--theme-color-main);
      width: 100%;
      height: 12px;
      transition: 0.3s all;
    }

    .counter {
      font-size: 20px;
      color: var(--theme-color5);
      transition: 0.3s all;
      margin-top: 15px;
    }

    .list {
      display: flex;
      width: 100%;
      flex-flow: column;

      .itemSearch{
        display: flex;
        flex-flow: column;
        padding: 35px 0 40px;
        border-bottom: 3px dotted var(--theme-color5) ;
        transition: 0.3s all;

        .link {
          display: flex;
        }

        .titleLink {
          font-size: 20px;
          color: var(--theme-color-main);
          display: flex;
          flex-flow: row;
          align-items: center;
          transition: 0.3s all;
          cursor: pointer;
        }

        .text {
          font-size: 16px;
          color: var(--theme-color12);
          transition: 0.3s all;
          margin-top: 13px;

          p {
            font-size: 16px !important;
            color: var(--theme-color12)!important;
            transition: 0.3s all;
            margin-top: 13px !important;
            font-weight: normal !important;
          }

          span{
            font-size: 16px !important;
            color: var(--theme-color12)!important;
            transition: 0.3s all;
            margin-top: 13px !important;
            font-weight: normal !important;
          }

          strong {
            font-size: 16px !important;
            color: var(--theme-color12)!important;
            transition: 0.3s all;
            margin-top: 13px !important;
            font-weight: normal !important;
          }

          .superStrong {
            font-weight: 600 !important;
          }

          .hideTooltip {
            display: none;
          }

          strong {
            font-weight: 700;
            letter-spacing: 0.5px;
          }
        }
      }

      .itemSearch:last-child {
        border-bottom: unset;
      }
    }
  }

  .morePhotosWrapper {
    display: flex;
    width: 100%;
    border-bottom: 5px dashed var(--theme-color8);
    align-items: center;
    justify-content: center;
    padding: 10px 0;

    .morePhotos {
      display: flex;
      flex-flow: column;
      align-items: center;
      margin-bottom: 10px;
      cursor: pointer;

      .more {
        color: var(--theme-color-main);
        font-size: 20px;
      }

      .moreArrow {
        width: 25px;
        height: 16px;
        margin-top: 8px;
        background-size: cover;
      }

      .color {
        background-image: url("../../assets/images/other/arrowDownColor.svg");
      }

      .black {
        background-image: url("../../assets/images/other/arrowDownBlack.svg");
      }
    }
  }
}

@media screen and (max-width: 1400px){
  .wrapper {

    .subWrapper {
      padding: 0 40px;
    }
  }
}

@media screen and (max-width: 800px){
  .wrapper {

    .subWrapper {

      .rightTitle {
        font-size: 21px;
        margin-bottom: 10px;
        flex-flow: column;
      }

      .bar{
        height: 9px;
      }

      .counter {
        font-size: 18px;
        margin-top: 11px;
      }

      .list {

        .itemSearch{
          padding: 25px 0 30px;

          .titleLink {
            font-size: 18px;
          }

          .text {
            font-size: 15px;
            margin-top: 10px;

          }
        }
      }
    }
  }
}

@media screen and (max-width: 520px){
  .wrapper {

    .subWrapper {
      padding: 0 30px;
    }
  }
}

@media screen and (max-width: 450px) {
  .wrapper {

    .subWrapper {

      .bar {
        height: 6px;
      }
    }
  }
}

@media screen and (max-width: 400px){
  .wrapper {

    .subWrapper {
      padding: 0 20px;
    }
  }
}

@media screen and (max-width: 355px){
  .wrapper {

    .subWrapper {

      .rightTitle {
        font-size: 19px;
        margin-bottom: 8px;
        flex-flow: column;
      }

      .counter {
        font-size: 16px;
        margin-top: 9px;
      }

      .list {

        .itemSearch{
          padding: 20px 0 25px;

          .titleLink {
            font-size: 16px;
          }

          .text {
            font-size: 13px;
            margin-top: 8px;
          }
        }
      }
    }
  }
}