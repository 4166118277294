.mainWrapper {
  width: 100%;
  background-color: var(--theme-page-background);

  .subWrapper {
    display: flex;
    max-width: 1480px;
    padding: 0 60px;
    margin: 0 auto;
    flex-flow: column;
  }

  .mainText {
    font-size: 20px;
    color: var(--theme-color12);
    text-align: center;
    max-width: 1200px;
    margin: 40px auto;
  }

  .tractatesWrapper {
    display: flex;
    justify-content: center;

    .containerWrapper {
      display: flex;
      width: calc(100% / 5 + 2.6px);

      &:last-child {
        width: calc(100% / 5 - 13px + 2.6px) ;
      }

      .container {
        border-top: 12px var(--theme-color-main) solid;
        border-bottom: 12px var(--theme-color3) solid;
        padding: 35px 5px 40px;
        display: flex;
        flex-flow: column;
        width: 100%;
        transition: 0.3s all;
        background-color: var(--theme-page-background);
        align-items: center;

        .author {
          font-size: 24px;
          color: var(--theme-color-main);
          font-weight: 500;
          text-align: center;
          transition: 0.3s all;
          height: 58px;

          span {
            background: linear-gradient(to right, rgb(255, 255, 255), rgb(255, 255, 255)), linear-gradient(to right, var(--theme-color-main), var(--theme-color-main));
            background-size: 100% 2px, 0 2px;
            background-position: 100% 100%, 0 100%;
            background-repeat: no-repeat;
            padding-bottom: 3px;
            transition: background-size 400ms;
          }

          &:hover {

            span {
              background-size: 0 2px, 100% 2px;
            }
          }
        }

        .year {
          font-size: 20px;
          color: var(--theme-color5);
          text-align: center;
          padding: 20px 0;
          transition: 0.3s all;
        }

        .title {
          font-size: 24px;
          color: var(--theme-color12);
          font-weight: 500;
          text-align: center;
          text-decoration: underline;
          text-underline-offset: 2px;
          cursor: pointer;
          transition: 0.3s all;
        }
      }

      .separator {
        margin: 35px 5.5px 40px;
        border-left: 2px var(--theme-color-main) dashed;
        transition: 0.3s all;
      }

      .separator {
        margin: 35px 5.5px 40px;
        border-left: 2px var(--theme-color-main) dashed;
        transition: 0.3s all;
      }
    }
  }

  .articlesWrapper {
    display: flex;
    flex-flow: column;
    width: 100%;
    margin-top: 38px;

    .container {
      display: flex;
      width: 100%;
      margin-top: 20px;
      justify-content: space-between;

      .multimediaWrapper {
        display: flex;
        flex-flow: column;
        background-color: var(--theme-color-main);
        width: calc((100% - 32px) / 3);
        flex-shrink: 0;
        padding-bottom: 30px;
        align-items: flex-start;
        margin-bottom: 30px;
        margin-right: 16px;

        .image {
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          width: 100%;
          height: 350px;
          background-color: var(--theme-color10);
          border: 3px solid var(--theme-color-main);
        }

        .date {
          font-size: 20px;
          color: var(--theme-color10);
          font-weight: 500;
          padding: 0 30px;
          margin: 23px 0 17px;
        }

        .title {
          font-size: 20px;
          color: var(--theme-color1);
          font-weight: 500;
          padding: 0 30px;
          margin-bottom: 17px;
        }

        a {
          font-size: 20px;
          color: var(--theme-color6);
          margin: auto 30px 0;
          position: relative;
        }

        a::after {
          content: '';
          position: absolute;
          bottom: -3px;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: var(--theme-color6);
          opacity: 0;
          transition: opacity 300ms, transform 300ms;
        }

        a:hover::after{
          opacity: 1;
          transform: translate3d(0, 0.2em, 0);
        }

        a:after {
          opacity: 1;
          transform: scale(0);
          transform-origin: center;
        }

        a:hover::after {
          transform: scale(1);
        }
      }

      .multimediaWrapper:nth-child(3n+3) {
        margin-right: unset;
      }

      .multimediaWrapper2 {
        width: calc((100% - 16px) / 2);
      }

      .multimediaWrapper2:nth-child(2) {
        margin-right: unset;
      }

      .multimediaWrapper4 {
        width: calc((100% - 16px) / 2);
        margin-right: unset;
      }

      .multimediaWrapper5 {
        width: calc((100% - 16px) / 2);
      }

      .multimediaWrapper5:nth-child(odd) {
        margin-right: unset;
      }

      .multimediaWrapper7 {
        margin-right: unset;
        width: calc((100% - 16px) /2);
      }

      .multimediaWrapper8 {
        width: calc((100% - 16px) / 2);
      }

      .multimediaWrapper8:nth-child(even) {
        margin-right: unset;
      }

      .articleWrapper {
        display: flex;
        flex-flow: column;
        border: 3px solid var(--theme-color3);
        padding: 60px 55px 40px 35px;
        align-items: flex-start;
        width: calc(61.5% - 15px);


        .author {
          font-size: 20px;
          color: var(--theme-color-main);
          margin-top: unset;
          margin-bottom: 40px;
        }

        .title {
          font-size: 24px;
          color: var(--theme-color12);
          font-weight: 500;
          font-style: italic;
          margin: 0 0 40px;
        }

        .text {
          font-size: 20px;
          color: var(--theme-color12);
          margin-bottom: 40px;
          margin-top: auto;

          p{
            display: none;
          }

          p:nth-child(1) {
            display: block;
          }

          .hideTooltip {
            display: none;
          }

          p {
            font-size: 20px !important;
            color: var(--theme-color12) !important;
            margin: unset !important;
          }

          span {
            font-size: 20px !important;
            color: var(--theme-color12) !important;
            margin: unset !important;
          }
        }

        a {
          font-size: 20px;
          color: var(--theme-color-main);
          margin: auto 0 0;
          position: relative;
        }

        a::after {
          content: '';
          position: absolute;
          bottom: -3px;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: var(--theme-color-main);
          opacity: 0;
          transition: opacity 300ms, transform 300ms;
        }

        a:hover::after{
          opacity: 1;
          transform: translate3d(0, 0.2em, 0);
        }

        a:after {
          opacity: 1;
          transform: scale(0);
          transform-origin: center;
        }

        a:hover::after {
          transform: scale(1);
        }
      }
    }

    .containerMultimedia {
      justify-content: center;
      flex-wrap: wrap;
    }

    .containerArticles {
      justify-content: space-between;

      .articleWrapper {
        width: calc(50% - 8px);
      }
    }
  }
}

@media screen and (max-width: 1400px) {
  .mainWrapper {

    .subWrapper {
      padding: 0 40px;
    }
  }
}

@media screen and (max-width: 1120px) {
  .mainWrapper {

    .tractatesWrapper {
      flex-wrap: wrap;
      justify-content: center;

      .containerWrapper {
        width: calc(100% / 3 + 4px);

        .container {

          .author {
            height: unset;
          }
        }
      }

      .containerWrapper:nth-child(3) {
        width: calc(100% / 3 - 13px + 4px) ;

        .separator {
          display: none;
        }
      }

      .containerWrapper:nth-child(4) {
        margin-top: 20px;
        width: calc(100% / 2 + 6.5px);
      }

      .containerWrapper:nth-child(5) {
        margin-top: 20px;
        width: calc(100% / 2 - 6.5px);
      }
    }

    .articlesWrapper {
      margin-top: 20px;

      .container {

        .multimediaWrapper {
          width: calc((100% - 16px) /2);

          .image {
            height: 300px;
          }

          .date {
            font-size: 18px;
            padding: 0 30px;
            margin: 23px 0 17px;
          }

          .title {
            font-size: 18px;
            padding: 0 30px;
            margin-bottom: 17px;
          }

          a {
            font-size: 18px;
          }
        }

        .multimediaWrapper:nth-child(even) {
          margin-right: unset;
        }

        .multimediaWrapper:nth-child(odd) {
          margin-right: 16px;
        }

        .multimediaWrapper4:nth-child(even) {
          margin-left: 16px;
        }

        .multimediaWrapper5:nth-child(even) {
          margin-left: 16px;
        }

        .articleWrapper {
          padding: 60px 25px 40px 25px;

          .author {
            font-size: 18px;
          }

          .title {
            font-size: 22px;
          }

          .text {
            font-size: 18px;

            p {
              font-size: 18px !important;
            }

            span {
              font-size: 18px !important;
            }
          }

          a {
            font-size: 18px;
            margin: auto 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1102px) {
  .mainWrapper {

    .tractatesWrapper {
      flex-wrap: wrap;
      justify-content: center;

      .containerWrapper {

        .container {

          .author {
            height: 58px;
          }
        }
      }

      .containerWrapper:nth-child(4) {
        .container {

          .author {
            height: unset;
          }
        }
      }

      .containerWrapper:nth-child(5) {
        .container {

          .author {
            height: unset;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .mainWrapper {

    .tractatesWrapper {

      .containerWrapper {
        width: calc(100% / 2);

        .container {

          .author {
            height: unset;
          }
        }
      }

      .containerWrapper:nth-child(2) {

        .separator {
          display: none;
        }
      }

      .containerWrapper:nth-child(3) {
        margin-top: 20px;
        width: 100%;

        .separator {
          display: block;
        }
      }

      .containerWrapper:nth-child(4) {
        margin-top: 20px;

        .separator {
          display: none;
        }
      }

      .containerWrapper:nth-child(5) {
        margin-top: 20px;
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .mainWrapper {

    .mainText {
      font-size: 18px;
      margin: 30px 0;
    }

    .tractatesWrapper {

      .containerWrapper {
        width: 100%;

        .container {
          padding: 25px 5px;

          .year {
            padding: 10px 0;
          }
        }
      }

      .containerWrapper:nth-child(1) {

        .separator {
          display: none;
        }
      }

      .containerWrapper:nth-child(2) {
        margin-top: unset;

        .container {
          border-top: unset;
        }
      }

      .containerWrapper:nth-child(3) {
        margin-top: unset;

        .container {
          border-top: unset;
        }

        .separator {
          display: none;
        }
      }

      .containerWrapper:nth-child(4) {
        width: 100%;
        margin-top: unset;

        .container {
          border-top: unset;
        }

      }

      .containerWrapper:nth-child(5) {
        margin-top: unset;

        .container {
          border-top: unset;
          border-bottom: 12px solid var(--theme-color-main);
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .mainWrapper {

    .articlesWrapper {
      margin-top: 40px;

      .container {
        flex-flow: column;
        margin-top: unset;

        .multimediaWrapper {
          width: 100%;
          margin-bottom: 30px;
          margin-right: unset !important;
        }

        .multimediaWrapper4:nth-child(even) {
          margin-left: unset;
        }

        .multimediaWrapper5:nth-child(even) {
          margin-left: unset;
        }

        .articleWrapper {
          border: unset;
          margin-left: unset;
          padding: 30px 0 30px;
          border-top: 3px dashed var(--theme-color8);
          border-bottom: 3px dashed var(--theme-color8);
          align-items: center;
          width: 100%;
          margin-bottom: 30px;

          .author {
            text-align: center;
            margin-bottom: 20px;
          }

          .title {
            text-align: center;
            margin: 0 0 20px;
          }

          .text {
            margin-bottom: 20px;
          }

          a {
            text-align: center;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 520px) {
  .mainWrapper {

    .subWrapper {
      padding: 0 30px;
    }

    .mainText {
      font-size: 16px;
      margin: 20px 0;
    }

    .tractatesWrapper {

      .containerWrapper {

        .container {
          padding: 20px 5px;
          border-width: 8px;

          .author {
            font-size: 20px;
          }

          .year {
            padding: 8px 0;
            font-size: 18px;
          }

          .title {
            font-size: 20px;
          }
        }
      }

      .containerWrapper:nth-child(5) {

        .container {
          border-bottom: 8px solid var(--theme-color-main);
        }
      }
    }

    .articlesWrapper {

      .container {

        .multimediaWrapper {
          margin-bottom: 30px;

          .image {
            height: 50.90vw;
          }

          .date {
            font-size: 16px;
            margin: 20px 0 15px;
          }

          .title {
            font-size: 16px;
          }

          a {
            font-size: 16px;
          }
        }

        .articleWrapper {

          .author {
            font-size: 16px;
            text-align: center;
          }

          .title {
            font-size: 20px;
            text-align: center;
            margin: 20px 0;
          }

          .text {
            font-size: 16px;
            margin-bottom: 20px;

            p {
              font-size: 16px !important;
            }

            span {
              font-size: 16px !important;
            }
          }

          a {
            font-size: 16px;
            text-align: center;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .mainWrapper {

    .subWrapper {
      padding: 0 20px;
    }

    .tractatesWrapper {

      .containerWrapper {

        .container {
          border-width: 8px;

          .author {
            font-size: 18px;
          }

          .year {
            padding: 8px 0;
            font-size: 16px;
          }

          .title {
            font-size: 18px;
          }
        }
      }
    }
  }
}