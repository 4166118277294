.wrapper {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;

  .imageWrapper {
    display: flex;
    width: 31.5%;
    height: 233px;
    margin-bottom: 23px;
    cursor: pointer;
    transition: 0.3s ease-in-out transform, 0.3s ease-in-out filter;

    .image {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      border: 2px solid var(--theme-color-main);
      transition: 0.3s ease-in-out transform, 0.3s ease-in-out filter;
      transform:perspective(100px) translateZ(0);
    }
  }

  .imageWrapper:nth-child(1n + 0 ) {
    margin-right: calc(5.5% / 2);
  }

  .imageWrapper:nth-child(2n+ 0 ) {
    margin-right: calc(5.5% / 2);
  }

  .imageWrapper:nth-child(3n+ 0 ) {
    margin-right: 0;
  }

  .fadeIn {
    animation: fadeInAnimation 0.6s linear;
  }

  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .imageWrapper:hover {

    .image {
      transform:scale(1.1);
      filter: brightness(1.05);
    }

  }

  .morePhotosWrapper {
    display: flex;
    width: 100%;
    border-bottom: 5px dashed var(--theme-color8);
    align-items: center;
    justify-content: center;
    padding: 10px 0;

    .morePhotos {
      display: flex;
      flex-flow: column;
      align-items: center;
      margin-bottom: 10px;
      cursor: pointer;

      .more {
        color: var(--theme-color-main);
        font-size: 20px;
      }

      .moreArrow {
        width: 25px;
        height: 16px;
        margin-top: 8px;
        background-size: cover;
      }

      .color {
        background-image: url("../../assets/images/other/arrowDownColor.svg");
      }

      .black {
        background-image: url("../../assets/images/other/arrowDownBlack.svg");
      }
    }
  }

  .galleryWrapper {
    display: flex;
    flex-flow: column;
    justify-content: center;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: var(--theme-background-transparent);
    visibility: hidden;
    opacity: 0;
    transition: 0.3s visibility, 0.3s opacity;
    z-index: 10000;

    .gallery {
      display: flex;
      flex-flow: column;
      margin: auto 0;
      width: 100%;
      padding: 100px 0 0;

      .trackWrapper {
        display: flex;
        flex-flow: row;
        margin: auto auto 25px;
        width: calc(100% - 120px);
        overflow: hidden;

        .track {
          display: flex;
          flex-flow: row;
          align-items: center;
          /*transition: 0.6s ease-in-out transform;*/
          position: relative;

          .itemWrapper {
            width: calc(100vw - 120px);
            display: flex;
            align-items: center;
            user-select: none;
            justify-content: center;
            height: 100%;

            .item {
              display: flex;
              max-width: calc(100%);
              background-size: contain;
              background-repeat: no-repeat;
              background-position: center;
              height: auto;
              transition: 0.3s all;
            }
          }
        }
      }

      .data {
        flex-flow: column;
        display: flex;
        align-items: center;
        text-align: center;
        padding: 0 20px 20px;

        span {
          color: var(--theme-color2);
        }
      }
    }
  }

  .galleryWrapperOpen {
    visibility: visible;
    opacity: 1;
  }

  .galleryWrapperToucher {
    display: flex;
    flex-flow: row;
    align-items: center;
    position: fixed;
    width: 100vw;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s visibility, 0.3s opacity;
    z-index: 10001;

    .close {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 50px;
      height: 50px;
      flex-shrink: 0;
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 1000000;
      cursor: pointer;
      transition: 0.3s all;
    }

    .close:hover {
      transform: scale(1.1);
    }

    .closeWhite {
      background-image: url("../../assets/images/other/closeWhite.png");
    }

    .closeBlack {
      background-image: url("../../assets/images/other/closeBlack.png");
    }

    .arrowLeft {
      width: 24px;
      height: 43px;
      cursor: pointer;
      position: absolute;
      left: 15px;
      top: 50%;
      transform: translateY(-50%) perspective(100px) translateZ(0);
      transition: 0.3s all;
    }

    .arrowRight {
      width: 24px;
      height: 43px;
      cursor: pointer;
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%) perspective(100px) translateZ(0);
      transition: 0.3s all;
    }

    .arrowLeft:hover {
      transform: translateY(-50%) perspective(100px) translateZ(15px);
    }

    .arrowRight:hover {
      transform: translateY(-50%) perspective(100px) translateZ(15px);
    }

  }

  .galleryWrapperToucherOpen {
    visibility: visible;
    opacity: 1;
  }
}

@media screen and (max-width: 940px) {
  .wrapper {

    .imageWrapper {
      height: 25vw;
    }

  }
}

@media screen and (max-width: 750px) {
  .wrapper {

    .imageWrapper {
      width: calc((100% - 23px) / 2);
      height: calc((100vw - 105px) / 2 * 0.9);
    }

    .imageWrapper:nth-child(1n + 0 ) {
      margin-right: unset;
    }

    .imageWrapper:nth-child(2n+ 0 ) {
      margin-right: unset;
    }

    .imageWrapper:nth-child(3n+ 0 ) {
      margin-right: unset;
    }

    .imageWrapper:nth-child(even) {
      margin-left: 23px;
    }
  }
}

@media screen and (max-width: 600px) {
  .wrapper {

    .galleryWrapper {

      .gallery {

        .trackWrapper {
          width: 100%;

          .track {

            .itemWrapper {
              width: 100vw;
              display: flex;
              align-items: center;
              user-select: none;
              justify-content: center;
              height: 100%;
            }
          }
        }
      }
    }

    .galleryWrapperToucher {

      .arrowLeft {
        display: none;
      }

      .arrowRight {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 520px) {
  .wrapper {

    .imageWrapper {
      width: calc((100% - 15px) / 2);
      height: calc((100vw - 65px) / 2 * 0.9);
      margin-bottom: 15px;
    }

    .imageWrapper:nth-child(even) {
      margin-left: 15px;
    }
  }
}

@media screen and (max-width: 450px) {
  .wrapper {

    .imageWrapper {
      width: 100%;
      height: calc((100vw - 60px) * 0.5);
    }

    .imageWrapper:nth-child(even) {
      margin-left: unset;
    }
  }
}

@media screen and (max-width: 400px) {
  .wrapper {

    .imageWrapper {
      height: calc((100vw - 40px) * 0.5);
    }
  }
}